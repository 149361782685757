import {
  Stack,
  HStack,
  VStack,
  Switch,
  Skeleton,
  useToast,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import DashboardLayout from "layouts/dashboard";
import { ReactComponent as BackIcon } from "icons/back.svg";
import { useNavigate, useParams } from "react-router-dom";
import { Path } from "utils/constant.data";
import { useRef, useState } from "react";
import {
  useGetSingleBeneficiaryQuery,
  useInitiateMandateMutation,
  useCancelMandateMutation,
  useGetAllWitnessQuery,
} from "store/services/loan";
import { capitalize, amountConverter, bankimage } from "utils/strings";
import { Colors } from "utils/constant.data";
import { format } from "date-fns";
import AddLoanModal from "./componenents/AddLoan";
import MandateConfirmationModal from "components/MandateConfirmationModal";
import bankBackground from "../../../image/bankBackground.png";
import AddWitnessModal from "./componenents/AddWitnessModal";
import WitnessTable from "components/tables/WitnessTabel";
const LoanDetails = () => {
  const navigate = useNavigate();
  const witnessSectionRef = useRef(null);
  const urlParams = useParams();
  const [fetch] = useState(false);
  const toast = useToast();
  const { data, isLoading } = useGetSingleBeneficiaryQuery(urlParams?.loanId, {
    skip: fetch,
  });

  const [initiateMandate, { isLoading: creating }] =
    useInitiateMandateMutation();

  const loanDetails = data?.data ?? null;

  const {
    data: witnessData,
    isLoading: witnessLoading,
    refetch: refetchWitness,
    error: witnessError,
  } = useGetAllWitnessQuery(loanDetails?.id, {
    skip: !loanDetails?.id,
  });

  console.log(loanDetails, "loandetails");
  const [showWitnessTable, setShowWitnessTable] = useState(false);

  const handleViewGuarantor = async () => {
    try {
      if (showWitnessTable) {
        setShowWitnessTable(false);
        return;
      }
      setShowWitnessTable(true);
      await refetchWitness();
      setTimeout(() => {
        witnessSectionRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 0);
    } catch (error) {
      console.error("Error fetching witness data:", error);
    }
  };

  console.log(witnessData?.results?.id, 'hshhsh');


  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();

  const { isOpen: isWitnessOpen, onOpen: onWitnessOpen, onClose: onWitnessClose } = useDisclosure();

  const [cancelMandate, { isLoading: cancelling }] = useCancelMandateMutation()

  const [selectedMandate, setSelectedMandate] = useState(null);

  const handleDeleteMandate = async () => {
    if (loanDetails?.mandates?.length <= 1) {

      toast({
        title: "Cannot Delete Mandate",
        description: "You cannot delete the last mandate. Mandates cannot be empty.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    const approvedMandates = loanDetails?.mandates?.filter((mandate) => mandate.status === "approved");

    // If there's exactly one approved mandate, don't allow deletion
    // if (approvedMandates.length === 1) {

    //   toast({
    //     title: "Cannot Delete Mandate",
    //     description: "You cannot delete the last approved mandate.",
    //     status: "warning",
    //     duration: 5000,
    //     isClosable: true,
    //     position: "top",
    //   });
    //   return;
    // }

    try {
      if (selectedMandate) {
        const result = await cancelMandate({
          beneficiary: selectedMandate.beneficiary,
          account_id: selectedMandate.id,
        }).unwrap();
        toast({
          title: "Mandate Disabled",
          description: result?.message || "The mandate has been successfully deleted.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        onConfirmClose();
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error?.data?.message || "Failed to delete the mandate.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const name =
    `${capitalize(loanDetails?.first_name)} ${capitalize(
      loanDetails?.last_name
    )}` ?? "";

  const details = [
    {
      label: "First Name",
      value: loanDetails?.first_name,
    },
    {
      label: "Last Name",
      value: loanDetails?.last_name,
    },

    {
      label: "Gender",
      value: loanDetails?.gender ?? "Male",
    },
    {
      label: "Date of Birth",
      value: loanDetails?.dob,
    },
    {
      label: "BVN",
      value: loanDetails?.bvn,
    },
    {
      label: "Status",
      value: loanDetails?.is_active ? "Active" : "Not Active",
      badge: true,
    },
    {
      label: "Created At",
      value: loanDetails?.created_at
        ? format(loanDetails?.created_at, "yyyy-MM-dd")
        : "Null",
    },
  ];



  const BankCard = ({ details, bg = "#826AF9" }) => {
    const matchingBank = bankimage.find(
      (bank) => bank.name.toLowerCase() === details.bankname.toLowerCase()
    );

    // const CurrentIcon = matchingBank ? matchingBank.icon : null;
    const CurrentIcon = matchingBank ? matchingBank?.icons : null;

    const currentBg = matchingBank ? matchingBank?.bg : bg;

    // Debug log to check the imported image
    console.log('Bank Background Image:', bankBackground);

    return (
      <Stack
        p="12px"
        textColor="white"
        className="relative overflow-hidden rounded-lg"

      >
        {/* Alternative way using inline styles */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${bankBackground})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            zIndex: 10,
          }}
        />

        {/* Overlay with bank color */}
        <div
          style={{
            backgroundColor: currentBg,
            opacity: 0.9,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
          }}
        />

        {/* Content */}
        <div className="relative z-10">
          <div className="flex justify-between">
            <div className="flex">
              <figure className="w-45 h-45 rounded-8 bg-gray-500 mr-12 flex items-center justify-center">
                {CurrentIcon && <CurrentIcon />}
              </figure>
              <div className="flex flex-col">
                <h6 className="font-normal text-12 font-inter">Beneficiary</h6>
                <h5 className="font-semibold text-6 font-inter">
                  {details.bankname}
                </h5>
              </div>
            </div>
            <div className="flex flex-col items-end">
              <h6 className="font-semibold text-sm font-inter mb-4">Direct Debit</h6>
              <div className="flex items-center">
                <p className="mr-4  text-6 font-inter">
                  {details?.status === "closed" || !details?.is_active ? "ON" : "OFF"}
                </p>
                <Switch
                  size="sm"
                  isChecked={details?.status !== "closed" && details?.is_active}
                  onChange={() => {
                    if (details?.is_active && details?.status !== "closed") {
                      setSelectedMandate(details);
                      onConfirmOpen();
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-6">
            <div className="flex gap-12 items-center">
              <h6 className="font-normal text-12 font-inter">Account Number</h6>
              <h6 className="px-8 py-4 rounded-8 border-2 border-white ml-8">Naira</h6>
            </div>
            <div className="flex justify-between mt-6">
              <h5 className="font-bold text-2xl font-urbanist">{details.accountnumber}</h5>
              <h6 className="px-8 py-4 rounded-8 border-2 border-white ml-8 ">
                Status: {details.is_active ? "Active" : "Inactive"}
              </h6>

            </div>
          </div>
        </div>
        <br />
      </Stack>
    );
  };




  return (
    <DashboardLayout>
      <section className="mx-flush">
        <h2 className="font-inter font-medium text-black text-2xl">
          Loan Beneficiary
        </h2>

        <Stack
          direction={{ base: "column", md: "row" }}
          spacing="24px"
          marginTop="24px"
        >
          {/* right section */}
          <Stack w="70%" bg="white" padding="28px" position="sticky"
            top="0" >
            {isLoading ? (
              <Stack w="100%" minH="20rem">
                <Skeleton height="120px" />
                <Skeleton height="130px" />
                <Skeleton height="140px" />
              </Stack>
            ) : (
              <Stack w="100%" minH="20rem">
                <Stack>
                  <button
                    onClick={() => navigate(Path.DASHBOARD_LOAN_BENEFICIARY)}
                    className="w-250 flex items-center  text-grey700"
                  >
                    {" "}
                    <BackIcon />
                    <span className="ml-8">Back to Overview</span>
                  </button>
                  <h2 className="font-inter font-semibold text-h6">
                    {`${name}`}'s Profile
                  </h2>
                </Stack>

                <section className="grid grid-cols-3 gap-2 my-8">
                  <Stack>
                    <div className="flex items-center gap-3">
                      <figure className="w-78 h-78 rounded-full bg-slate-500 mb-10"></figure>
                      {/* <h5 className="font-inter font-semibold text-h6 text-blue200">
                      {name}
                    </h5> */}
                      <h6 className="font-inter font-semibold text-h6 text-[#425466]">
                        <span className="text-[#75818D]">User ID:</span> #
                        {loanDetails?.merchant?.substring(0, 6)}
                      </h6>
                    </div>
                    <div className="flex items-center gap-4">
                      <button
                        onClick={() => {
                          onWitnessOpen();
                        }}
                        className={`btn btn-primary !w-170 mt-8 flex items-center justify-center rounded-md   `}
                      >
                        Add Guarantor
                      </button>



                      <button
                        onClick={handleViewGuarantor}
                        className="btn btn-primary !w-170 mt-8 flex items-center justify-center rounded-md"
                      >
                        {showWitnessTable ? "Hide Guarantor" : "View Guarantor"}
                      </button>
                    </div>
                  </Stack>


                  <Stack bg="#FBFBFD" padding="16px">
                    <HStack>
                      <VStack alignItems="flex-start" gap="0">
                        <h5 className="text-dark500 font-normal font-inter  text-12">
                          Number of Loans
                        </h5>
                        <h4 className="text-dark500 font-bold text-h4">
                          {/* {getRandomCharacters(loanDetails?.id)} */}

                          {loanDetails?.loans?.length}
                        </h4>
                      </VStack>
                      <VStack alignItems="flex-start" gap="0" marginLeft="30px">
                        <h5 className="text-dark500 font-normal font-inter  text-12">
                          Pending
                        </h5>
                        <h4 className="text-dark500 font-bold text-h4">
                          {loanDetails?.loans?.filter(
                            (_) => _?.status.toLowerCase() === "pending"
                          )?.length ?? 0}
                        </h4>
                      </VStack>
                    </HStack>


                    <div className="flex items-center gap-4">
                      <button
                        onClick={() => {
                          navigate(`${Path.LOAN_LIST}/${loanDetails?.id}`, {
                            state: {
                              loanDetails,
                            },
                          });
                        }}
                        className={`btn btn-primary !w-170 mt-8 flex items-center justify-center rounded-md ${loanDetails?.loans?.length > 0 ? "" : "btn-disabled"
                          }  `}
                      >
                        View Loans
                      </button>
                      <button
                        onClick={() => {
                          onOpen();
                        }}
                        className={`btn btn-primary !w-170 mt-8 flex items-center justify-center rounded-md ${loanDetails?.mandates?.length > 0 ? "" : "btn-disabled"
                          }  `}
                      >
                        Add Loan
                      </button>
                    </div>
                  </Stack>
                  <Stack bg="#FBFBFD" padding="16px" gap="0">
                    <Stack gap="0" mb="10px">
                      <h5 className="text-dark500 font-normal font-inter  text-12">
                        Total Loan
                      </h5>
                      <h5 className="text-dark500 font-bold text-h4">
                        ₦{amountConverter(loanDetails?.total_loan_amount)}
                      </h5>
                    </Stack>
                    <Stack gap="0">
                      <h5 className="text-dark500 font-normal font-inter  text-12">
                        Total Paid
                      </h5>

                      <h5 className="text-dark500 font-bold text-h4">
                        ₦{amountConverter(loanDetails?.total_paid_amount)}
                      </h5>
                    </Stack>
                  </Stack>
                </section>

                <Stack mt="24px">
                  {details?.map((_) => {
                    return (
                      <div className="flex flex-row justify-between items-center border-b pt-4 pb-12 text-grey950">
                        <h6 className="font-inter font-normal text-sm text-grey950">
                          {_?.label}
                        </h6>
                        <h6 className="font-inter font-normal text-sm text-[#181830]">
                          {_?.value}
                        </h6>
                      </div>
                    );
                  })}
                </Stack>
              </Stack>

            )}


            <section ref={witnessSectionRef} className="mt-8">
              {showWitnessTable && (
                <div>

                  {witnessLoading ? (
                    <Spinner size="lg" />
                  ) : (
                    <WitnessTable
                      witnessData={witnessData}
                      isLoading={witnessLoading}
                      error={witnessError}
                      witnessId={witnessData?.id}

                    />
                  )}
                </div>
              )}
            </section>
          </Stack>

          {/* left side  */}
          <Stack w="30%" minH="40rem" overflowY="auto" maxH="100vh">
            {isLoading ? (
              <Stack w="100%" minH="20rem">
                <Skeleton height="20px" />
                <Skeleton height="30px" />
                <Skeleton height="40px" />
              </Stack>
            ) : (
              <Stack w="100%">
                <h4 className="font-inter font-medium text-black text-lg">
                  Beneficiary Linked Accounts
                </h4>
                {loanDetails?.mandates?.length > 0 ? (
                  loanDetails?.mandates?.map((_) => {
                    return <BankCard details={_} />;
                  })
                ) : (
                  <Stack pt="12px" textColor="white">
                    <button
                      onClick={async () => {
                        await initiateMandate({
                          id: loanDetails?.id,
                        })
                          .unwrap()
                          .then((data) => {
                            toast({
                              title: data?.message,
                              description: data?.detail,
                              status: "success",
                              duration: 9000,
                              isClosable: true,
                              position: "top",
                            });
                            window.open(data?.data?.url, "_blank");
                          })
                          .catch((error) => {
                            toast({
                              title: "Error Occured",
                              description: error?.data?.detail,
                              status: "error",
                              duration: 9000,
                              isClosable: true,
                              position: "top",
                            });
                          });
                      }}
                      className="btn btn-primary"
                      disabled={creating ? true : false}
                    >
                      {creating ? (
                        <Spinner color={Colors.white} />
                      ) : (
                        " Get account mandate"
                      )}
                    </button>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </section>

      <AddLoanModal isOpen={isOpen} onClose={onClose} id={loanDetails?.id} />

      <AddWitnessModal
        isWitnessOpen={isWitnessOpen}
        onWitnessClose={onWitnessClose}
        id={loanDetails?.id}
      />

      <MandateConfirmationModal
        header="Remove Mandate"
        text="You’re trying to remove this from our service. Are you sure?"
        isOpen={isConfirmOpen}
        onClose={onConfirmClose}
        onConfirm={handleDeleteMandate}
      />
    </DashboardLayout>
  );
};

export default LoanDetails;
