import { Table, Thead, Tbody, Tr, Th, Td, Badge } from "@chakra-ui/react";
import { Colors } from "utils/constant.data";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import { useMemo } from "react";
import EmptyState from "components/EmptyState";
import { capitalize, amountConverter } from "utils/strings";
import { mapBadge } from "utils/strings";
// import DetailsModal from "pages/dashbaord/transactions/componenents/TransactionDetails";






const WalletTable = ({ transactions, metadata }) => {
  const COLUMNS = (ee) => [
    {
      Header: "Payment Ref",
      accessor: "reference",
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return (
          <Td width="15%" padding="0" border="0">
            <h6>{value ? value.substring(0, 10) : "Null"}</h6>
          </Td>
        );
      },
    },
    {
      Header: "Source",
      accessor: "source",
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return (
          <Td border="0" pl="0">
            <h6>{capitalize(value)}</h6>
          </Td>
        );
      },
    },

    {
      Header: "Amount",
      accessor: "amount",
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return (
          <Td border="0" pl="0">
            <h6>₦{amountConverter(value)} </h6>
          </Td>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return (
          <Td border="0" pl="0">
            <Badge variant="solid" colorScheme={mapBadge(value)}>
              {capitalize(value)}
            </Badge>
          </Td>
        );
      },
    },
    // {
    //   Header: "",
    //   accessor: "id",
    //   disableFilters: true,
    //   sortable: false,
    //   Cell: ({ cell }) => {
    //     const { isOpen, onOpen, onClose } = useDisclosure();
    //     return (
    //       <Td border="0">
    //         <button
    //           onClick={() => {
    //             onOpen();
    //           }}
    //           className="cursor-pointer text-primary font-bold"
    //         >
    //           View
    //         </button>

    //         <DetailsModal
    //           isBeneficiaryOpen={isOpen}
    //           onBeneficiaryOpen={onOpen}
    //           onBeneficiaryClose={onClose}
    //           transaction={cell?.row?.original}
    //         />
    //       </Td>
    //     );
    //   },
    // },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => COLUMNS("ee"), []);

  const tableInstance = useTable(
    { columns, data: transactions, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <section className="h-[25rem] overflow-y-scroll">
      {transactions && transactions?.length > 0 ? (
        <Table
          variant="simple"
          bgColor={Colors.white}
          mt="2rem"
          {...getTableProps()}
        >
          <Thead className="dashboard__table__header">
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    scope="col"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Tr padding="0" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Td pt="0" pb="0" {...cell.getCellProps()}>
                        {" "}
                        {cell.render("Cell")}{" "}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : (
        <EmptyState />
      )}
    </section>
  );
};

export default WalletTable;
