/** @format */

import { createApi } from "@reduxjs/toolkit/query/react";
import { API_TAGS } from "utils/strings";
import baseQueryWithReauth from "./baseQuery";

export const loanApi = createApi({
  reducerPath: "loanApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: [],
  endpoints: (builder) => ({

    createLoan: builder.mutation({
      query: (body) => {
        return {
          url: `merchant/loan-beneficiary/${body?.id}/create_loan/`,
          method: "POST",
          body,
        };
      },
    }),
    createBenefeciary: builder.mutation({
      query: (body) => {
        return {
          url: `merchant/loan-beneficiary/`,
          method: "POST",
          body,
        };
      },
      providesTags: [API_TAGS.CREATE_LOAN_BENEFICIARY],
    }),
    getAllBeneficiary: builder.query({
      query: () => `merchant/loan-beneficiary`,
      providesTags: [API_TAGS.GET_ALL_BENEFICIARY],

    }),
    getSingleBeneficiary: builder.query({
      query: (id) => `merchant/loan-beneficiary/${id}`,
      providesTags: [API_TAGS.GET_SINGLE_BENEFICIARY],

    }),


    deleteBeneficiary: builder.mutation({
      query: (body) => ({
        url: `merchant/loan-beneficiary/${body?.id}/delete`,
        method: "DELETE",
      }),
      providesTags: [API_TAGS.DELETE_SINGLE_BENEFICIARY],
    }),
    deleteLoanBeneficiary: builder.mutation({
      query: (body) => ({
        url: `merchant/loan-beneficiary/${body?.id}/`,
        method: "DELETE",
        invalidatesTags: [API_TAGS.GET_ALL_BENEFICIARY],
      }),
    }),
    initiateMandate: builder.mutation({
      query: (body) => ({
        url: `merchant/loan-beneficiary/${body?.id}/generate_consent/`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.INITIATE_MANDATE],
    }),
    finalizeMandate: builder.mutation({
      query: (body) => ({
        url: `merchant/loan-beneficiary/${body?.id}/generate_account/`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.FINALIZE_MANDATE],
    }),

    getWalletHistory: builder.query({
      query: () => ({
        url: `merchant/wallet/history`,
      }),
      providesTags: [API_TAGS.FINALIZE_MANDATE],
    }),
    fundWallet: builder.mutation({
      query: (body) => {
        return {
          url: `merchant/wallet/fund`,
          method: "POST",
          body,
        };
      },
    }),

    // cancel mandate
    cancelMandate: builder.mutation({
      query: (body) => ({
        url: `merchant/loan-beneficiary/${body?.beneficiary}/cancel_mandate/`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.CANCEL_MANDATE],
    }),


    createWitness: builder.mutation({
      query: (body) => {
        return {
          url: `merchant/witness/add/${body.id}`,
          method: "POST",
          body,
        };
      },
      providesTags: [API_TAGS.CREATE_WITNESS],
    }),

    getAllWitness: builder.query({
      query: (id) => `/merchant/witness/list/${id}`,
      providesTags: [API_TAGS.GET_ALL_WITNESS],
      method: 'GET'

    }),


    deleteWitness: builder.mutation({
      query: (id) => ({
        url: `merchant/witness/${id}/delete`,
        method: "GET",
        invalidatesTags: [API_TAGS.DELETE_WITNESS],
      }),
    }),
    updateWitness: builder.mutation({
      query: (body) => ({
        url: `merchant/witness/${body?.id}/update-status`,
        method: "POST",
        body,
      }),
      invalidatesTags: [API_TAGS.UPDATE_WITNESS],
    }),
    //https://dev-api.usetunnel.com/api/v1/merchant/loan-beneficiary/b05880ba-5705-49bf-9878-d6cfa56b3540/get_single_mandate/

    selectMandate: builder.mutation({
      query: (body) => {
        return {
          url: `/merchant/loan-beneficiary/${body?.id}/get_single_mandate/`,
          method: "POST",
          body,
        };
      },
      providesTags: [API_TAGS.CREATE_WITNESS],
    }),

    sendMandateConsent: builder.mutation({
      query: (body) => ({
        url: `merchant/loan-beneficiary/${body?.id}/send_mandate_consent/`,
        method: 'POST',
        body,
      }),
    }),
  }),


});

export const {
  useCreateLoanMutation,
  useCreateBenefeciaryMutation,
  useGetAllBeneficiaryQuery,
  useGetSingleBeneficiaryQuery,
  useDeleteBeneficiaryMutation,
  useInitiateMandateMutation,
  useFinalizeMandateMutation,
  useGetWalletHistoryQuery,
  useFundWalletMutation,
  useDeleteLoanBeneficiaryMutation,
  useCancelMandateMutation,
  useCreateWitnessMutation,
  useSelectMandateMutation,

  useGetSingleWalletQuery,
  useGetAllWitnessQuery,
  useDeleteWitnessMutation,
  useUpdateWitnessMutation,
  useSendMandateConsentMutation,


} = loanApi;
