import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Stack,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { ReactComponent as BackIcon } from "icons/back.svg";
import { Colors } from "utils/constant.data";
import { capitalize, formatUnderScore } from "utils/strings";
import { useDeactivateUserMutation } from "store/services/team";
// import { format } from "date-fns";

const MemberDetailsModal = ({ isOpen, onOpen, onClose, details }) => {
  const OverlayOne = () => (
    <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(5px)" />
  );
  const toast = useToast();

  const _details = [
    {
      label: "Email",
      value: `${details?.email}`,
    },
    {
      label: "Status",
      value: `${details?.is_active ? "Active" : "Deactivated"}`,
    },
  ];

  const [deactivateUser, { isLoading: deactivating }] =
    useDeactivateUserMutation();


  const handleSubmit = async () => {
    await deactivateUser({
      id: details?.id,
      permission: details?.permission,
      is_active: !details?.is_active ,
    })
      .unwrap()
      .then((data) => {
        toast({
          title: "Success",
          description: "Team Member Updated",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      })
      .catch((error) => {
        toast({
          title: "Error Occured",
          description: error?.data?.detail,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      });
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
    >
      <OverlayOne />
      <ModalContent w="100%" maxW="1282px">
        <ModalCloseButton />
        <ModalBody padding="0">
          <Stack padding="0">
            <Stack
              padding="20px 34px 30px 34px"
              borderBottom="1px solid #E0E0E0"
            >
              <button
                onClick={() => onClose()}
                className="w-250 flex items-center  text-grey700"
              >
                {" "}
                <BackIcon />
                <span className="ml-8">Back</span>
              </button>
            </Stack>

            <Stack direction="row" padding="48px" gap="45px">
              <Stack w="35%" padding="28px" gap="0">
                <Stack
                  bg="#FAFAFA"
                  padding="28px"
                  // borderBottom="1px solid #DFE3E8"
                >
                  <h5 className="mb-16 font-semibold text-lg"> User Details</h5>
                  <Stack>
                    <h6 className="text-purple200 text-lg font-medium">
                      {capitalize(details?.email)}
                    </h6>
                    <p className="text-grey700 text-lg">
                      {" "}
                      {capitalize(formatUnderScore(details?.permission))}
                    </p>
                    <button
                      className="btn btn-primary mt-20 !px-20"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      {deactivating ? (
                        <Spinner color="white" />
                      ) : (
                        details?.is_active ? "Deactivate User" : "Activate User"
                      )}
                    </button>
                  </Stack>
                </Stack>
                {/* <Stack bg="#FAFAFA" padding="28px">
                  <div className="flex flex-row justify-between items-center pt-4 pb-12">
                    <h6 className="font-inter font-normal text-sm text-grey700">
                      BVN
                    </h6>
                    <h6 className="font-inter font-normal text-lg text-[#007ACE]">
                      {details?.beneficiary?.bvn}
                    </h6>
                  </div>
                  <div className="flex flex-row justify-between items-center pt-4 pb-12 text-">
                    <h6 className="font-inter font-normal text-sm text-grey700">
                      Total details
                    </h6>
                    <h6 className="font-inter font-normal text-lg text-[#181830]">
                      {`₦${amountConverter(details?.amount)}`}
                    </h6>
                  </div>
                  <div className="flex flex-row justify-between items-center pt-4 pb-12">
                    <h6 className="font-inter font-normal text-sm text-grey700">
                      Wallet Balance
                    </h6>
                    <h6 className="font-inter font-normal text-lg text-[#181830]">
                      ₦{amountConverter(0)}
                    </h6>
                  </div>
                </Stack> */}
              </Stack>
              <Stack
                w="65%"
                border={`1px solid ${Colors.grey500}`}
                bg={Colors.white}
                padding="28px"
              >
                {/* <HStack borderBottom={`1px solid ${Colors.grey500}`} pb="32px">
                  <div className="flex flex-col pt-4 pb-12 text-[#75818D]">
                    <h6 className="font-inter font-semibold text-2xl text-blue200 mb-6">
                      Payment successful!
                    </h6>
                    <p className="font-inter font-normal text-sm mb-4">
                      {`₦${amountConverter(details?.amount)}`} was swept from{" "}
                      {capitalize(details?.beneficiary?.first_name)}{" "}
                      {capitalize(details?.beneficiary?.last_name)}
                    </p>
                    <p className="font-inter font-normal text-sm">
                      {capitalize(details?.bank_name)}
                    </p>
                  </div>
                </HStack> */}

                <Stack mt="24px">
                  {_details?.map((_) => {
                    return (
                      <div className="flex flex-row justify-between items-center border-b pt-4 pb-12 text-grey950">
                        <h6 className="font-inter font-normal text-sm text-grey950">
                          {_?.label}
                        </h6>

                        <h6 className="font-inter font-normal text-sm text-[#181830]">
                          {_?.value}
                        </h6>
                      </div>
                    );
                  })}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MemberDetailsModal;
