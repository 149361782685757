import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import AuthLayout from "layouts/auth";
import OtpInput from "react-otp-input";
import { useState } from "react";
import { ReactComponent as SuccessIcon } from "icons/succes.svg";
import { Path } from "utils/constant.data";
import { HStack } from "@chakra-ui/react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useResetPasswordMutation, useValidateOTPMutation } from "store/services/authAPI";

const ConfirmResetPage = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const [currentPage, setCurrentPage] = useState(1);
  const [otp, setOtp] = useState("");
  const { session } = location.state || {}
  const [validateOTP] = useValidateOTPMutation();
  const [resetPassword] = useResetPasswordMutation();

  // Formik for password form
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.password || values.password.length < 8) {
        errors.password = "Password must be at least 8 characters";
      }
      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = "Passwords do not match";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        const response = await resetPassword({
          password: values.password,
          otp,
          session
        }).unwrap();

        if (response?.status === true) {
          toast.success("Password reset successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            setCurrentPage(3); // Go to success page
          }, 3000);

        } else {
          toast.error(response?.message || "An error occurred!");
        }
      } catch (error) {
        toast.error(error?.data?.message || "Failed to reset password!");
      }
    },
  });

  const handleOtpSubmit = async () => {
    if (otp.length === 6) {
      try {
        const response = await validateOTP({ otp, session }).unwrap();

        if (response?.status === true) {
          toast.success("OTP validated successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            setCurrentPage(2)
          }, 3000);


        } else {
          toast.error(response?.message || "Invalid OTP!");
        }
      } catch (error) {
        toast.error(error?.data?.message || "Failed to validate OTP!");
      }
    } else {
      toast.error("Please enter a valid 6-digit OTP!");
    }
  };

  return (
    <AuthLayout>
      <ToastContainer />

      <div className="mb-8 text-center">
        {/* Display step number */}
        <p className="text-sm text-gray-500">Step {currentPage} of 3</p>
      </div>

      {currentPage === 1 && (
        <section className="w-full flex justify-center flex-col h-full p-178">
          <h1 className="text-h2 font-urbanist font-bold text-dark100 mb-4">
            OTP Authentication
          </h1>
          <HStack>
            <h1 className="text-p text-dark200 font-graphik">
              We sent you an OTP code to reset your password via your email
            </h1>
          </HStack>
          <form className="auth__form w-full mt-24">
            <div>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<div style={{ marginLeft: "8px" }}></div>}
                renderInput={(props) => <input {...props} />}
                isInputNum={true}
                shouldAutoFocus={true}
                inputStyle={{
                  border: "1px solid transparent",
                  width: "76px",
                  height: "76px",
                  fontSize: "24px",
                  color: "#33B469",
                  fontWeight: "800",
                  caretColor: "#33B469",
                  backgroundColor: "#F5F5F5",
                  fontFamily: `"Urbanist, "sans-serif"`,
                }}
                focusStyle={{
                  border: "2px solid #33B469",
                }}
              />
            </div>
            <div className="flex my-32">
              <button
                type="button"
                onClick={handleOtpSubmit}
                className="btn btn-primary w-full"
              >
                Continue
              </button>
            </div>
            <p className="font-display text-[#696F79] font-normal text-base">
              Didn’t receive the code?
              <button
                className="text-purple100 pl-6 font-medium"
                onClick={() => toast.info("Resending code...")}
              >
                Resend Code
              </button>
            </p>
          </form>
        </section>
      )}

      {currentPage === 2 && (
        <section className="w-full flex justify-center flex-col h-full p-178">
          <h1 className="text-h2 font-urbanist font-bold text-dark100 mb-4">
            Set New Password
          </h1>
          <h1 className="text-p text-dark200 font-graphik">
            Must be at least 8 characters
          </h1>
          <form
            className="auth__form w-full mt-24"
            onSubmit={formik.handleSubmit}
          >
            <div className="auth-form-group">
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Enter new password"
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              {formik.errors.password && (
                <p className="error">{formik.errors.password}</p>
              )}
            </div>
            <div className="auth-form-group">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm your password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
              />
              {formik.errors.confirmPassword && (
                <p className="error">{formik.errors.confirmPassword}</p>
              )}
            </div>
            <div className="flex my-12">
              <button type="submit" className="btn btn-primary w-full">
                Submit
              </button>
            </div>
          </form>
        </section>
      )}

      {currentPage === 3 && (
        <section className="w-full flex justify-center flex-col h-full p-178">
          <figure className="mb-24">
            <SuccessIcon />
          </figure>
          <h1 className="text-h2 font-urbanist font-bold text-dark100 mb-24">
            Password Reset Successfully!
          </h1>
          <h1 className="text-p text-dark200 font-graphik">
            Your password has been reset successfully. Click "Continue" to log
            in to your account.
          </h1>
          <div className="flex my-32">
            <button
              type="button"
              onClick={() => navigate(Path.LOGIN)}
              className="btn btn-primary w-full"
            >
              Continue
            </button>
          </div>
        </section>
      )}
    </AuthLayout>
  );
};

export default ConfirmResetPage;
