import React, { useState, useEffect } from "react";
import DashboardLayout from "layouts/dashboard";
import {
    IconButton,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    useDisclosure,
    useToast,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Input,
    FormControl,
    FormLabel,
    Select,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import {
    useGetMerchantProfileQuery,
    useLazyGetReGenerateKeyQuery,
    useLazyGetWebhokQuery,
    useCreateWebhookMutation,
    useDeleteWebhookMutation,
    useUpdateWebhookMutation
} from "store/services/business";
import EmptyState from "components/EmptyState";
import MenuBtn from "../../../image/Button.png";

const DashboardApiSdk = () => {
    const { data, isLoading, refetch } = useGetMerchantProfileQuery();
    const [triggerGetWebhooks, { data: webhookData, isLoading: isWebhookLoading }] = useLazyGetWebhokQuery();
    const [triggerReGenerateKey] = useLazyGetReGenerateKeyQuery();
    const [createWebhook, { isLoading: isCreatingWebhook }] = useCreateWebhookMutation();
    const [deleteWebhook] = useDeleteWebhookMutation();
    const [updateWebhook, { isLoading: isUpdatingWebhook }] = useUpdateWebhookMutation();

    const [tab, setTab] = useState("API");
    const [loading, setLoading] = useState(false);
    const [webhookForm, setWebhookForm] = useState({ name: "", merchant: "", url: "", status: true });
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isEditMode, setIsEditMode] = useState(false);
    const [editingWebhookId, setEditingWebhookId] = useState(null);
    const toast = useToast();


    useEffect(() => {
        if (tab === "Webhooks") {
            triggerGetWebhooks();
        }
    }, [tab, triggerGetWebhooks]);

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        toast({
            title: "Copied!",
            description: "The key has been copied to your clipboard.",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top-right",
        });
    };

    const handleRegenateKey = async () => {
        setLoading(true);
        try {
            await triggerReGenerateKey().unwrap();
            toast({
                title: "Success!",
                description: "Keys regenerated successfully.",
                status: "success",
                duration: 2000,
                isClosable: true,
                position: "top-right",
            });
            await refetch();
        } catch (error) {
            toast({
                title: "Error!",
                description: error?.data?.message || "Failed to regenerate keys.",
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "top-right",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleCreateOrUpdateWebhook = async () => {
        const payload = { ...webhookForm };
        const action = isEditMode ? updateWebhook({ id: editingWebhookId, ...payload }) : createWebhook(payload);
        const successMessage = isEditMode ? "Webhook updated successfully." : "Webhook created successfully.";

        try {
            await action.unwrap();
            toast({
                title: "Success!",
                description: successMessage,
                status: "success",
                duration: 2000,
                isClosable: true,
                position: "top-right",
            });
            triggerGetWebhooks();
            resetFormAndCloseModal();
        } catch (error) {
            toast({
                title: "Error!",
                description: error?.data?.message || "Failed to save webhook.",
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "top-right",
            });
        }
    };

    // Reset form and close modal
    const resetFormAndCloseModal = () => {
        setWebhookForm({ name: "", merchant: "", url: "", status: true });
        setIsEditMode(false);
        setEditingWebhookId(null);
        onClose();
    };

    // Open edit modal
    const openEditModal = (webhook) => {
        setWebhookForm(webhook);
        setIsEditMode(true);
        setEditingWebhookId(webhook.id);
        onOpen();
    };






    const handleDeleteWebhook = async (id) => {
        try {
            await deleteWebhook(id).unwrap();
            toast({
                title: "Success!",
                description: "Webhook deleted successfully.",
                status: "success",
                duration: 2000,
                isClosable: true,
                position: "top-right",
            });
            triggerGetWebhooks();
        } catch (error) {
            toast({
                title: "Error!",
                description: error?.data?.message || "Failed to delete webhook.",
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "top-right",
            });
        }
    };

    const sdkKey = data?.data?.live_public_key || "N/A";
    const apiKey = data?.data?.live_secret_key || "N/A";
    const currentKey = tab === "SDK" ? sdkKey : apiKey;

    return (
        <DashboardLayout>
            <section className="mx-flush">
                <h2 className="font-inter font-medium text-black text-xl flex justify-start gap-12">
                    {["API", "SDK", "Webhooks"].map((item) => (
                        <button
                            key={item}
                            className={`py-4 px-12 ${tab === item ? "border-b-2 border-red-500 text-xl font-medium" : "text-gray-800"
                                }`}
                            onClick={() => setTab(item)}
                        >
                            {item}
                        </button>
                    ))}
                </h2>

                <div className="min-h-screen">
                    <main className="p-6">
                        {tab === "API" || tab === "SDK" ? (
                            <div>
                                <div className="flex justify-between items-center mb-8">
                                    <p className="text-gray-600 font-semibold text-sm">
                                        You are viewing {tab} keys
                                    </p>
                                    <button
                                        onClick={handleRegenateKey}
                                        className={`bg-primary text-white px-20 py-10 rounded-lg text-lg font-semibold ${loading && "opacity-50 cursor-not-allowed"
                                            }`}
                                        disabled={loading}
                                    >
                                        {loading ? "Regenerating Keys..." : "Regenerate Keys"}
                                    </button>
                                </div>
                                {isLoading ? (
                                    <EmptyState />
                                ) : (
                                    <div className="bg-white rounded-lg border px-10 dashboard__table__body">
                                        <div className="p-12 border-b flex justify-between items-center">
                                            <span className="text-gray-500 text-sm font-medium">
                                                {tab === "API" ? "Live Key" : "Public Key"}
                                            </span>
                                            <div className="flex items-center gap-4 p-10">
                                                <span className="text-gray-500 font-mono text-sm">{currentKey}</span>
                                            </div>
                                            <button
                                                onClick={() => handleCopy(currentKey)}
                                                className="text-gray-600"
                                            >
                                                <CopyIcon className="w-5 h-5" /> <span className="text-sm">Copy</span>
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="">
                                <div className="flex justify-between items-center mb-4">
                                    <h3 className="text-gray-700 text-lg font-semibold"></h3>
                                    <button onClick={onOpen} className="bg-primary text-white px-20 py-10 rounded-lg text-lg font-semibold ">
                                        New Webhook
                                    </button>
                                </div>
                                <br />
                                {isWebhookLoading ? (
                                    <div className="flex justify-center items-center py-6">
                                        <Spinner size="lg" />
                                    </div>
                                ) : (
                                    <table className="w-full text-left">
                                        <thead>
                                            <tr className="dashboard__table__header">
                                                <th className="py-2 px-12 border-b">Key</th>
                                                <th className="py-2 px-12 border-b">URL</th>
                                                <th className="py-2 px-12 border-b">Status</th>
                                                <th className="py-2 px-12 border-b text-right">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {webhookData?.results?.map((webhook) => (
                                                <tr key={webhook.id}>
                                                    <td className="py-2 px-12 border-b">{webhook.merchant}</td>
                                                    <td className="py-2 px-12 border-b">{webhook.url}</td>
                                                    <td className="py-2 px-12 border-b">
                                                        {webhook.status ? "Active" : "Inactive"}
                                                    </td>
                                                    <td className="py-2 px-12 border-b text-right flex gap-2 items-center justify-end">
                                                        <Menu>
                                                            <MenuButton
                                                                as={IconButton}
                                                                aria-label="Options"
                                                                icon={<Image src={MenuBtn} />}
                                                                variant="unstyled"
                                                            />
                                                            <MenuList minW="150px" p="2">


                                                                <MenuItem
                                                                    fontSize="sm"
                                                                    onClick={() => handleCopy(webhook.url)}
                                                                >
                                                                    Copy
                                                                </MenuItem>
                                                                <MenuItem
                                                                    fontSize="sm"
                                                                    onClick={() => openEditModal(webhook)}
                                                                >
                                                                    Edit
                                                                </MenuItem>
                                                                <MenuItem
                                                                    fontSize="sm"
                                                                    onClick={() =>
                                                                        handleDeleteWebhook(webhook.id)
                                                                    }
                                                                >
                                                                    Delete
                                                                </MenuItem>

                                                            </MenuList>
                                                        </Menu>

                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        )}
                    </main>
                </div>

                {/* Create Webhook Modal */}
                <Modal isOpen={isOpen} onClose={resetFormAndCloseModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{isEditMode ? "Edit Webhook" : "Create Webhook"}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {/* <FormControl isRequired>
                                <FormLabel>Name</FormLabel>
                                <Input
                                    type="text"
                                    value={webhookForm.name}
                                    onChange={(e) =>
                                        setWebhookForm({ ...webhookForm, name: e.target.value })
                                    }
                                />
                            </FormControl> */}

                            {/* <FormControl isRequired mt={4}>
                                <FormLabel>Merchant</FormLabel>
                                <Input
                                    type="text"
                                    value={webhookForm.merchant}
                                    onChange={(e) =>
                                        setWebhookForm({ ...webhookForm, merchant: e.target.value })
                                    }
                                />
                            </FormControl> */}

                            <FormControl isRequired mt={4}>
                                <FormLabel>URL</FormLabel>
                                <Input
                                    type="url"
                                    value={webhookForm.url}
                                    onChange={(e) =>
                                        setWebhookForm({ ...webhookForm, url: e.target.value })
                                    }
                                />
                            </FormControl>

                            <FormControl isRequired mt={4}>
                                <FormLabel>Status</FormLabel>
                                <Select



                                    value={webhookForm.status.toString()} // Convert boolean to string for dropdown
                                    onChange={(e) =>
                                        setWebhookForm({ ...webhookForm, status: e.target.value === "true" }) // Convert back to boolean
                                    }
                                >
                                    <option value="true">Active</option>
                                    <option value="false">Inactive</option>
                                </Select>
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button variant="outline" onClick={onClose} mr={3}>
                                Cancel
                            </Button>
                            <Button
                                colorScheme="bg-primary"
                                onClick={handleCreateOrUpdateWebhook}
                                isLoading={isCreatingWebhook || isUpdatingWebhook}
                                className="bg-primary text-white px-20 py-10 rounded-lg text-lg font-semibold"
                            >
                                {isEditMode ? "Update" : "Create"} Webhook
                            </Button>

                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </section>
        </DashboardLayout>
    );
};

export default DashboardApiSdk;
