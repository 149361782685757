import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Stack,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { Colors } from "utils/constant.data";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Path } from "utils/constant.data";
import { useMemo } from "react";
import EmptyState from "components/EmptyState";
import { capitalize, amountConverter } from "utils/strings";
import { format } from "date-fns";
import { ReactComponent as ViewIcon } from "icons/view.svg";
import { ReactComponent as DeleteIcon } from "icons/delete.svg";
import { useDeleteLoanBeneficiaryMutation } from "store/services/loan";

const BenefeciaryTable = ({ beneficiaries, metadata }) => {
  const COLUMNS = (ee) => [
    {
      Header: "Name",
      accessor: "first_name",
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        const { first_name, last_name } = cell?.row?.original;
        const name = `${capitalize(first_name)} ${capitalize(last_name)}` ?? "";
        return (
          <Td width="15%" padding="0" border="0">
            <Stack direction="row" alignItems="center">
              {/* <figure className="w-50 h-50 rounded-full bg-slate-500"></figure> */}
              <h6>{name?.substring(0, 25)}</h6>
            </Stack>
          </Td>
        );
      },
    },
    {
      Header: "Means of Identification",
      accessor: "bvn",
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return (
          <Td border="0" pl="0">
            <h6>{value}</h6>
          </Td>
        );
      },
    },
    {
      Header: "Date",
      accessor: "created_at",
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return (
          <Td border="0" pl="0">
            <h6>{format(new Date(value), "dd MMM YYY")}</h6>
          </Td>
        );
      },
    },
    {
      Header: "Loan Amount",
      accessor: "total_loan_amount",
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return (
          <Td border="0" pl="0">
            <h6>₦{amountConverter(value)} </h6>
          </Td>
        );
      },
    },
    {
      Header: "Loan Status",
      accessor: "is_active",
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return (
          <Td border="0" pl="0">
            <div className={`badge-container ${value ? "success" : "danger"}`}>
              <span></span>
              <p>{value ? "Active" : "Inactive"}</p>
            </div>
          </Td>
        );
      },
    },
    {
      Header: "Action",
      accessor: "id",
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        const navigate = useNavigate();
        const { isOpen, onOpen, onClose } = useDisclosure();
        const cancelRef = useRef();
        const [deleteLoanBeneficiary, { isLoading: deleting }] =
          useDeleteLoanBeneficiaryMutation();
        const toast = useToast();

        return (
          <Td border="0">
            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Delete Beneficiary
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    Are you sure? You can't undo this action afterwards.
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={() => {
                        deleteLoanBeneficiary({ id: value })
                          .unwrap()
                          .then((data) => {
                            toast({
                              title: data?.message,
                              description: data?.detail,
                              status: "success",
                              duration: 9000,
                              isClosable: true,
                              position: "top",
                            });
                            window.location.reload();
                            onClose();
                          })
                          .catch((error) => {
                            toast({
                              title: "Error Occured",
                              description: error?.data?.detail,
                              status: "error",
                              duration: 9000,
                              isClosable: true,
                              position: "top",
                            });
                          });
                      }}
                      ml={3}
                    >
                      {deleting ? <Spinner color={Colors.white} /> : "Delete"}
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
            <div className="flex items-center">
              <button
                onClick={() => {
                  onOpen();
                }}
                className="cursor-pointer text-primary font-bold p-10 hover:bg-gray-200 hover:bg-opacity-50 rounded-8 transition-all"
              >
                <DeleteIcon />
              </button>
              <button
                onClick={() => {
                  navigate(`${Path.LOAN_DETAILS}/${value}`);
                }}
                className="cursor-pointer text-primary font-bold p-10 hover:bg-gray-200 hover:bg-opacity-50  rounded-8 transition-all"
              >
                <ViewIcon />
              </button>
            </div>
          </Td>
        );
      },
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => COLUMNS("ee"), []);

  const tableInstance = useTable(
    { columns, data: beneficiaries, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <section className="h-[25rem] overflow-y-scroll">
      {beneficiaries && beneficiaries?.length > 0 ? (
        <Table
          variant="simple"
          bgColor={Colors.white}
          mt="2rem"
          {...getTableProps()}
        >
          <Thead className="dashboard__table__header">
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    scope="col"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}> {cell.render("Cell")} </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : (
        <EmptyState />
      )}
    </section>
  );
};

export default BenefeciaryTable;
