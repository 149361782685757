import {
    Modal,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    Stack,
    useToast,
    FormControl,
    FormLabel,
    Select,
    NumberInput,
    NumberInputField,
    Button,
    Input,
    FormErrorMessage, // Add FormErrorMessage for showing errors
} from "@chakra-ui/react";
import BlurOverlay from "layouts/components/BlurOverlay";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useGetAllBankAccountQuery } from "store/services/business";
import { useState } from "react";
import OtpInput from "react-otp-input";

const WithdrawWallet = ({ isOpen, onClose, beneficiaryId }) => {
    const [isOtpOpen, setIsOtpOpen] = useState(false);
    const toast = useToast();
    const { data: banks } = useGetAllBankAccountQuery();
    const bankList = banks?.results || [];

    const formik = useFormik({
        initialValues: {
            mandate: "",
            amount: "",
            accountNumber: "",
            otp: "",
        },
        validationSchema: Yup.object({
            mandate: Yup.string().required("Bank selection is required"),
            amount: Yup.string().required("Amount is required"),
            otp: Yup.string().when('isOtpOpen', {
                is: true,
                then: Yup.string().required("OTP is required"),
            }),
        }),
        onSubmit: (values) => {
            if (!isOtpOpen) {
                setIsOtpOpen(true);
                onClose();
            } else {
                handleOtpSubmit(values.otp);
            }
        },
    });

    const handleOtpSubmit = (otp) => {
        toast({
            title: "Transaction Confirmed.",
            description: "Your withdrawal has been successfully processed.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
        setIsOtpOpen(false);
        onClose();
    };

    const handleBankChange = (event) => {
        const selectedBankId = event.target.value;
        const selectedBank = bankList.find((bank) => bank.id === selectedBankId);

        if (selectedBank) {
            formik.setValues({
                ...formik.values,
                mandate: selectedBankId,
                accountNumber: selectedBank.account_number,
            });
        }
    };

    return (
        <>
            <Modal
                closeOnOverlayClick={false}
                size="xl"
                isOpen={isOpen}
                onClose={onClose}
                isCentered
            >
                <BlurOverlay />
                <ModalContent>
                    <ModalCloseButton className="rounded-full mt-1" />
                    <ModalBody padding="20px">
                        <Stack p={5} borderTop="1px solid #E0E0E0" mt={10} textAlign="center">
                            <h2 className="font-inter font-semibold text-h6 mt-5">Make Withdraw</h2>
                            <p>Enter your account details</p>
                        </Stack>
                        <Stack as="form" padding="20px" onSubmit={formik.handleSubmit} spacing={4}>
                            <FormControl
                                isInvalid={!!formik.errors.mandate && formik.touched.mandate}
                            >
                                <FormLabel>Select Bank</FormLabel>
                                <Select
                                    name="mandate"
                                    placeholder="Choose your bank"
                                    value={formik.values.mandate}
                                    onChange={handleBankChange}
                                    onBlur={formik.handleBlur}
                                    height="60px"
                                    borderRadius="10"
                                >
                                    {bankList.map((bank) => (
                                        <option key={bank.id} value={bank.id}>
                                            {bank.bank}
                                        </option>
                                    ))}
                                </Select>
                                <FormErrorMessage>{formik.errors.mandate}</FormErrorMessage>
                            </FormControl>

                            <FormControl>
                                <FormLabel>Account Number</FormLabel>
                                <NumberInput value={formik.values.accountNumber} isReadOnly>
                                    <NumberInputField
                                        placeholder="Account number will appear here"
                                        variant="filled"
                                        bg="#EDEDED"
                                        borderColor="transparent"
                                        borderRadius="10"
                                        color="#424242"
                                        height="60px"
                                    />
                                </NumberInput>
                            </FormControl>

                            <FormControl
                                isInvalid={!!formik.errors.amount && formik.touched.amount}
                            >
                                <FormLabel>Amount</FormLabel>
                                <NumberInput>
                                    <NumberInputField
                                        name="amount"
                                        value={formik.values.amount}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        placeholder="Enter amount"
                                        variant="filled"
                                        bg="#EDEDED"
                                        borderColor="transparent"
                                        borderRadius="10"
                                        color="#424242"
                                        height="60px"
                                    />
                                </NumberInput>
                                <FormErrorMessage>{formik.errors.amount}</FormErrorMessage>
                            </FormControl>

                            <button
                                type="submit"
                                width="full"
                                mt={4}
                                className="p-3 btn-primary btn rounded-md"
                            >
                                Withdraw
                            </button>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* OTP Modal */}
            <Modal
                isOpen={isOtpOpen}
                onClose={() => setIsOtpOpen(false)}
                isCentered
                size="xl"
                motionPreset="slideInBottom"
            >
                <BlurOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody padding="40px">
                        <Stack spacing={8} textAlign="center">
                            <h2 className="font-inter font-semibold text-h6">Security Check</h2>
                            <p>To confirm this withdrawal, we sent an OTP to your email</p>

                            <FormControl isInvalid={!!formik.errors.otp && formik.touched.otp}>
                                <FormLabel>OTP</FormLabel>
                                <OtpInput
                                    name="otp"
                                    value={formik.values.otp}
                                    onChange={(value) => formik.setFieldValue("otp", value)}
                                    onBlur={formik.handleBlur}
                                    numInputs={6}
                                    renderSeparator={<div style={{ marginLeft: "8px" }}></div>}
                                    renderInput={(props) => <input {...props} />}
                                    isInputNum={true}
                                    shouldAutoFocus={true}
                                    inputStyle={{
                                        border: "1px solid transparent",
                                        width: "76px",
                                        height: "76px",
                                        fontSize: "24px",
                                        color: "#33B469",
                                        fontWeight: "800",
                                        caretColor: "#33B469",
                                        backgroundColor: "#F5F5F5",
                                        fontFamily: `"Urbanist, "sans-serif"`,
                                    }}
                                    focusStyle={{
                                        border: "2px solid #33B469",
                                    }}
                                />
                                <FormErrorMessage>{formik.errors.otp}</FormErrorMessage>
                            </FormControl>

                            <Button
                                size="xlg"
                                height="60px"
                                className="btn-primary btn mt-6"
                                onClick={() => handleOtpSubmit("dummyOtp")}
                            >
                                Confirm Transaction
                            </Button>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default WithdrawWallet;
