import DashboardLayout from "layouts/dashboard";
import { useState } from "react";
import MerchantProfile from "./profile";
import BankAccount from "./BankAccount";
import TeamManagement from "./TeamManagement";

const DashboardSettings = () => {
  const [active, setActive] = useState("profile");

  return (
    <DashboardLayout>
      <section className="mx-flush">
        <div className="flex items-center justify-between my-20">
          <h2 className="font-inter font-medium text-black text-2xl">
            Settings
          </h2>
        </div>
        <div className="flex items-center border-b border-grey900  my-20">
          <button
            onClick={() => setActive("profile")}
            className={`font-inter font-medium text-sm  text-grey800 px-20 py-20 border-b-2 ${active === "profile" ? "border-b-purple100 text-purple100" : ""
              }`}
          >
            {" "}
            Organisation Details
          </button>
          <button
            onClick={() => setActive("bank_account")}
            className={`font-inter font-medium text-sm  text-grey800 px-20 py-20 border-b-2 ${active === "bank_account"
              ? "border-b-purple100 text-purple100"
              : ""
              }`}
          >
            {" "}
            Accounts
          </button>
          <button
            onClick={() => setActive("team_management")}
            className={`font-inter font-medium text-sm  text-grey800 px-20 py-20 border-b-2 ${active === "team_management"
              ? "border-b-purple100 text-purple100"
              : ""
              }`}
          >
            {" "}
            Teams
          </button>
        </div>

        {active === "profile" && <MerchantProfile />}
        {active === "bank_account" && <BankAccount />}
        {active === "team_management" && <TeamManagement />}
      </section>
    </DashboardLayout>
  );
};

export default DashboardSettings;
