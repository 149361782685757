import { HStack } from "@chakra-ui/react";
// import { ReactComponent as FilterIcon } from "icons/filter.svg";
// import { ReactComponent as FindIcon } from "icons/find.svg";
import { Badge } from "@chakra-ui/react";

const TableContainer = ({
  title,
  badgeText = "Items",
  children,
  showBadge = false,
  count = 0,
  handleRoute = () => { },
  minH = false,
  showNew = true,
  btnText = "Add New",
  showWithdraw,
  Withrawtext,
  handleWalletRoute
}) => {
  return (
    <section className={minH ? "min__table__card" : "table__card"}>
      <div className="flex items-center justify-between mb-17">
        <HStack flexBasis="40%" justifyContent="flex-start">
          <h5>
            {title}{" "}
            {showBadge && (
              <Badge
                marginLeft="8px"
                backgroundColor="#F9F5FF"
                borderRadius="16px"
                padding="8px"
                color="#6941C6
"
              >
                {count} {badgeText ? badgeText : "Items"}
              </Badge>
            )}
          </h5>
        </HStack>

        <div className="basis-1/2 flex-shrink-0 flex items-center justify-end gap-12">
          {/* <button className="btn btn-primary !px-20 mr-16">Export</button> */}
          {showWithdraw && (
            <button className="btn btn-primary !px-20" onClick={handleWalletRoute}>
              {Withrawtext}
            </button>
          )}
          {showNew && (
            <button className="btn btn-primary !px-20" onClick={handleRoute}>
              {btnText}
            </button>
          )}


        </div>
      </div>
      {/* <div className="flex items-center justify-start">
        <div className="search__content">
          <FindIcon className="mr-12" />
          <input type="text" id="User" placeholder="Search" name="user"></input>
        </div>
        <button className="btn btn-grey !text-dark200 flex items-center !px-22">
          <FilterIcon className="mr-16" />
          Filter
        </button>
      </div> */}
      {children}
    </section>
  );
};

export default TableContainer;
