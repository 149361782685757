import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Alert,
    AlertIcon,
    Center,
    Text,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Stack,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    IconButton,
    Image,
    useToast,
    Spinner
} from "@chakra-ui/react";
import { useState } from "react";
import deleteIcon from "../../icons/delete.svg";
import editIcon from "../../icons/add.svg";
import MenuBtn from "../../image/Button.png";
import { useDeleteWitnessMutation } from "store/services/loan";
import { useUpdateWitnessMutation } from "store/services/loan";

const WitnessTable = ({ witnessData, isLoading, error, onUpdateWitness }) => {
    const [deleteWitness, { isLoading: deleting }] = useDeleteWitnessMutation();
    const [updateWitness, { isLoading: updating }] = useUpdateWitnessMutation();
    const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();

    const [selectedWitness, setSelectedWitness] = useState(null);
    const [editForm, setEditForm] = useState({
        witnessname: "",
        witnessaddress: "",
        witnessoccupation: "",
        status: true
    });

    const toast = useToast();

    const handleEditClick = (witness) => {
        if (!witness) {
            console.error("Witness data is undefined!");
            return;
        }
        setSelectedWitness(witness);
        setEditForm({
            witnessname: witness.witnessname || "",
            witnessaddress: witness.witnessaddress || "",
            witnessoccupation: witness.witnessoccupation || ""
        });
        onEditOpen();
    };

    const handleDeleteClick = (witness) => {
        if (!witness) {
            console.error("Witness data is undefined!");
            return;
        }
        setSelectedWitness(witness);
        onDeleteOpen();
    };

    const handleConfirmDelete = async () => {
        if (!selectedWitness?.id) {
            console.error("No witness selected for deletion!");
            return;
        }

        try {
            const response = await deleteWitness(selectedWitness.id).unwrap();
            console.log("Delete response:", response); // Log the response for debugging

            toast({
                title: "Witness Deleted",
                description: "The witness was deleted successfully.",
                status: "success",
                duration: 5000,

                isClosable: true,
                position: "top",
            });

            onDeleteClose();
            if (onUpdateWitness) {
                onUpdateWitness(); // Refresh the witness list
            } else {
                console.error("onUpdateWitness callback is not provided!");
            }
        } catch (error) {
            console.error("Error deleting witness:", error); // Log the actual error
            toast({
                title: "Deletion Failed",
                description: "Failed to delete the witness. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleSaveChanges = async () => {
        if (!selectedWitness?.id) {
            console.error("No witness selected for update!");
            return;
        }

        const updatedData = {
            id: selectedWitness.id,
            status: true,
            ...editForm
        };

        try {
            const response = await updateWitness(updatedData).unwrap();
            console.log("Update response:", response);

            toast({
                title: "Witness Updated",
                description: "The witness details have been updated successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "top",
            });

            onEditClose();
            if (onUpdateWitness) {
                onUpdateWitness(); // Refresh the witness list
            } else {
                console.error("onUpdateWitness callback is not provided!");
            }
        } catch (error) {
            console.error("Error updating witness:", error);
            toast({
                title: "Update Failed",
                description: "Failed to update the witness. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    if (isLoading) {
        return (
            <Center p={8}>
                <Spinner size="xl" />
            </Center>
        );
    }

    if (error) {
        return (
            <Alert status="error">
                <AlertIcon />
                Error loading witnesses. Please try again.
            </Alert>
        );
    }

    return (
        <>
            <TableContainer>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Guarantor Name</Th>
                            <Th>Guarantor Address</Th>
                            <Th>Guarantor Occupation</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {witnessData?.results?.map((witness) => (
                            <Tr key={witness.id}>
                                <Td>{witness.witnessname}</Td>
                                <Td>{witness.witnessaddress}</Td>
                                <Td>{witness.witnessoccupation}</Td>
                                <Td>
                                    <Menu>
                                        <MenuButton
                                            as={IconButton}
                                            icon={<Image src={MenuBtn} />}
                                            variant="ghost"
                                            size="sm"
                                            aria-label="Options"
                                        />
                                        <MenuList minW="150px" p="2">
                                            <MenuItem
                                                fontSize="sm"
                                                icon={<img src={editIcon} alt="Edit" />}
                                                onClick={() => handleEditClick(witness)}
                                            >
                                                Edit
                                            </MenuItem>
                                            <MenuItem
                                                fontSize="sm"
                                                icon={<img src={deleteIcon} alt="Delete" />}
                                                onClick={() => handleDeleteClick(witness)}
                                                color="red.500"
                                            >
                                                Delete
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Td>
                            </Tr>
                        ))}
                        {!witnessData?.results?.length && (
                            <Tr>
                                <Td colSpan={4}>
                                    <Text textAlign="center" py={4}>
                                        No witnesses found.
                                    </Text>
                                </Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </TableContainer>

            {/* Edit Modal */}
            <Modal isOpen={isEditOpen} onClose={onEditClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Witness Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack spacing={4}>
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Input
                                    value={editForm.witnessname}
                                    onChange={(e) =>
                                        setEditForm({ ...editForm, witnessname: e.target.value })
                                    }
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Address</FormLabel>
                                <Input
                                    value={editForm.witnessaddress}
                                    onChange={(e) =>
                                        setEditForm({ ...editForm, witnessaddress: e.target.value })
                                    }
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Occupation</FormLabel>
                                <Input
                                    value={editForm.witnessoccupation}
                                    onChange={(e) =>
                                        setEditForm({ ...editForm, witnessoccupation: e.target.value })
                                    }
                                />
                            </FormControl>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={onEditClose}>
                            Cancel
                        </Button>
                        <Button

                            className="btn btn-primary"
                            isLoading={updating}
                            onClick={handleSaveChanges}
                        >
                            Save Changes
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Confirm Delete</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Are you sure you want to delete this witness? This action cannot be undone.
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={onDeleteClose}>
                            Cancel
                        </Button>
                        <Button
                            colorScheme="red"
                            isLoading={deleting}
                            onClick={handleConfirmDelete}
                        >
                            Delete
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default WitnessTable;
