import { Stack, Skeleton, TableContainer } from "@chakra-ui/react";
import { useState } from "react";
import EmptyState from "components/EmptyState";
import { useDisclosure } from "@chakra-ui/react";
import { useGetAllUsersQuery } from "store/services/team";
import TeamTable from "components/tables/TeamTable";
import AddMember from "../team/componenents/AddMember";

const TeamManagement = () => {
  const [skip] = useState(false);
  const [filter] = useState(`page=1`);

  const {
    data,
    isLoading: fetching,
    isFetching,
  } = useGetAllUsersQuery(filter, {
    skip: skip,
  });

  // console.log(data);


  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Stack>
      <section className="dashboard__accounts__container">
        <div className="flex items-center justify-between  px-24 py-34 bg-white rounded-t-6">
          <h2 className="font-inter font-medium text-dark400 text-xl">
            Team Management
          </h2>
          <button className="btn btn-primary !px-24" onClick={() => onOpen()}>Add Member</button>
        </div>
        <Stack>
          {fetching || isFetching ? (
            <Stack>
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </Stack>
          ) : data?.data?.length > 0 ? (
            <div className="px-24 pb-24 bg-white ">
              <TableContainer
                title="Team Members"
                count={data?.data?.length}
                handleRoute={() => {
                  onOpen();
                }}
                showBadge={false}
                showNew={true}
                btnText="Add Team Member"
              >
                <TeamTable transactions={data?.data} />
              </TableContainer>
            </div>
          ) : (
            <section className="flex items-center justify-center min-h-300 bg-white">
              <EmptyState title="No team member at the moment" />
            </section>
          )}
        </Stack>
        <AddMember isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      </section>
    </Stack>
  );
};

export default TeamManagement;
