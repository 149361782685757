// import DashboardLayout from "layouts/dashboard";
// import EmptyState from "components/EmptyState";
// import LoanCard from "components/LoanCard";
// import { useState } from "react";
// import TableContainer from "components/TableContainer";
// import { Skeleton, Stack, Spinner } from "@chakra-ui/react";
// import WalletTable from "components/tables/WalletTable";
// import { amountConverter } from "utils/strings";
// import { useGetMerchantProfileQuery } from "store/services/business";
// import FundWallet from "./componenents/FundWallet";
// import { useDisclosure } from "@chakra-ui/react";
// import { useGetWalletHistoryQuery, useGetAllBeneficiaryQuery } from "store/services/loan";
// import WithdrawWallet from "./componenents/WithdrawWallet";

// const DashboardWallet = () => {
//   const { data: stats, isLoading: statsLoading } = useGetMerchantProfileQuery();

//   const statData = [
//     {
//       title: "Wallet Balance",
//       number: `₦${amountConverter(stats?.data?.wallet_balance)}`,
//       percent: "0",
//       showFilter: false,
//     },
//   ];

//   const [filter] = useState(`page=1`);
//   const { data, isLoading: historyLoading, isFetching } = useGetWalletHistoryQuery(filter);

//   const { isOpen, onOpen, onClose } = useDisclosure();
//   const {
//     isOpen: isWalletOpen,
//     onOpen: onWalletOpen,
//     onClose: onWalletClose,
//   } = useDisclosure();

//   // Fetch beneficiaries
//   const { data: beneficiaries, isLoading: beneficiariesLoading, error: beneficiariesError } =
//     useGetAllBeneficiaryQuery();
//   const beneficiaryId = beneficiaries.map((beneficiary) => {
//     beneficiaries?.result?.id
//   })

//   return (
//     <DashboardLayout>
//       <section className="mx-flush">
//         <h2 className="font-inter font-medium text-black text-2xl">Wallet</h2>

//         {statsLoading ? (
//           <section className="my-16">
//             <Spinner
//               thickness="4px"
//               speed="0.65s"
//               emptyColor="gray.200"
//               color="blue.500"
//               size="xl"
//             />
//           </section>
//         ) : (
//           <section className="grid grid-cols-3 gap-16 my-16">
//             {statData.map((item) => (
//               <LoanCard data={item} key={item.title} />
//             ))}
//           </section>
//         )}

//         <section className="grid grid-cols-1 gap-16 my-16">
//           {historyLoading || isFetching ? (
//             <Stack>
//               <Skeleton height="40px" />
//               <Skeleton height="80px" />
//               <Skeleton height="140px" />
//             </Stack>
//           ) : (
//             <TableContainer
//               title="Wallet History"
//               count={data?.results?.length}
//               handleRoute={onOpen}
//               showBadge={true}
//               showWithdraw={true}
//               Withrawtext="Withdraw Wallet"
//               showNew={true}
//               btnText="Fund Wallet"
//               handleWalletRoute={onWalletOpen}
//             >
//               {data?.results?.length > 0 ? (
//                 <WalletTable transactions={data?.results} />
//               ) : (
//                 <section className="flex items-center justify-center min-h-300">
//                   <EmptyState />
//                 </section>
//               )}
//             </TableContainer>
//           )}
//         </section>
//       </section>

//       <FundWallet isOpen={isOpen} onClose={onClose} />
//       <WithdrawWallet
//         isOpen={isWalletOpen}
//         onClose={onWalletClose}
//         beneficiaryId={beneficiaries?.id}
//         beneficiariesLoading={beneficiariesLoading}
//         beneficiariesError={beneficiariesError}
//       />
//     </DashboardLayout>
//   );
// };

// export default DashboardWallet;


import DashboardLayout from "layouts/dashboard";
import EmptyState from "components/EmptyState";
import LoanCard from "components/LoanCard";
import { useState } from "react";
import TableContainer from "components/TableContainer";
import { Skeleton, Stack, Spinner } from "@chakra-ui/react";
import WalletTable from "components/tables/WalletTable";
import { amountConverter } from "utils/strings";
import { useGetMerchantProfileQuery } from "store/services/business";
import FundWallet from "./componenents/FundWallet";
import { useDisclosure } from "@chakra-ui/react";
import WithdrawWallet from "./componenents/WithdrawWallet";

import {
  // useFundWalletMutation,
  useGetWalletHistoryQuery,
} from "store/services/loan";

const DashboardWallet = () => {
  const { data: stats, isLoading } = useGetMerchantProfileQuery();
  console.log(stats, "merchant profile");

  const statData = [
    {
      title: "Wallet Balance",
      number: `₦${amountConverter(stats?.data?.wallet_balance)}`,
      percent: "0",
      showFilter: false,
    },
  ];

  const [skip] = useState(false);
  const [filter] = useState(`page=1`);

  const {
    data,
    isLoading: fetching,
    isFetching,
  } = useGetWalletHistoryQuery(filter, {
    skip: skip,
  });
  console.log(data, "wallet profile");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isWalletOpen, onOpen: onWalletOpen, onClose: onWalletClose } = useDisclosure()

  return (
    <DashboardLayout>
      <section className="mx-flush">
        <h2 className="font-inter font-medium text-black text-2xl">Wallet</h2>

        {isLoading ? (
          <section className="my-16">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </section>
        ) : (
          <section className="grid grid-cols-3 gap-16 my-16">
            {statData?.map((_) => {
              return <LoanCard data={_} key={_?.title} />;
            })}
          </section>
        )}

        <section className="grid grid-cols-1 gap-16 my-16">
          {fetching || isFetching ? (
            <Stack>
              <Skeleton height="40px" />
              <Skeleton height="80px" />
              <Skeleton height="140px" />
            </Stack>
          ) : (
            <TableContainer
              title="Wallet History"
              count={data?.results?.length}
              handleRoute={() => {
                onOpen();
              }}
              showBadge={true}
              showNew={true}
              btnText="Fund Wallet"
              showWithdraw={true}
              Withrawtext="Wallet Withdraw"
              handleWalletRoute={onWalletOpen}
            >
              {data?.results?.length > 0 ? (
                <WalletTable transactions={data?.results} />
              ) : (
                <section className="flex items-center justify-center min-h-300">
                  <EmptyState />
                </section>
              )}
            </TableContainer>
          )}
        </section>
      </section>
      <FundWallet isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      <WithdrawWallet
        isOpen={isWalletOpen}
        onClose={onWalletClose}
      />
    </DashboardLayout>
  );
};

export default DashboardWallet;
