/* eslint-disable no-unused-vars */
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import AuthLayout from "layouts/auth";
import { Path } from "utils/constant.data";
import { useNavigate } from "react-router-dom";
import { useForgetPasswordMutation } from "store/services/authAPI";
import { toast } from "react-toastify";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [forgetPassword] = useForgetPasswordMutation();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      try {
        const response = await forgetPassword({ email: values.email }).unwrap();
        console.log(response, "99999");
        const session = response?.data?.session_id
        console.log(session, "1199999");

        if (session) {
          // Show success toast message
          toast.success("Password reset link sent successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });


          setTimeout(() => {
            navigate(Path.RESET_PASSWORD, {
              state: { session, otpSent: true },
            });
          }, 3000);
        }
        else {
          throw new Error("Session data is missing from the response.");
        }

      } catch (error) {
        toast.error(
          error?.data?.detail || "An error occurred. Please try again.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    },
  });

  return (
    <AuthLayout>
      <section className="w-full flex justify-center flex-col h-full p-178">
        <h1 className="text-h2 font-urbanist font-bold text-dark100 mb-16">
          Forgot Password?
        </h1>
        <p className="text-p text-dark200 font-graphik">
          No worries, we’ll send you reset instructions via your registered email.
        </p>

        <form className="auth__form w-full mt-24" onSubmit={formik.handleSubmit}>
          <div className="auth-form-group">
            <label htmlFor="email">Your email</label>
            <input
              type="email"
              id="email"
              placeholder="mail@robinjobs.com"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="input-field"
            />
            {formik.touched.email && formik.errors.email && (
              <p className="error">{formik.errors.email}</p>
            )}
          </div>

          <div className="flex my-12">
            <button type="submit" className="btn btn-primary w-full">
              Continue
            </button>
          </div>

          <p className="font-normal font-graphik text-dark200 text-sm mt-24">
            <Link to={Path.LOGIN} className="text-blue">
              Back To Login
            </Link>
          </p>
        </form>
      </section>
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
