import { Stack, HStack, Skeleton, useToast, Badge, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { ReactComponent as BackIcon } from "icons/back.svg";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import DashboardLayout from "layouts/dashboard";
import LoanListTable from "./LoanListTable";
import { Path } from "utils/constant.data";
import { bankimage, capitalize } from "utils/strings";
import EmptyState from "components/EmptyState";
import { useCancelMandateMutation, useGetSingleBeneficiaryQuery } from "store/services/loan";
import MandateConfirmationModal from "components/MandateConfirmationModal";
import { BankCard, UserDetails } from "./componenents/LoanList";

const LoanList = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state;
  const [fetch, setFetch] = useState(false);
  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();

  const [cancelMandate, { isLoading: cancelling }] = useCancelMandateMutation();
  const [selectedMandate, setSelectedMandate] = useState(null);

  const loan = state?.loanDetails ?? null;
  const { data, isLoading, isFetching } = useGetSingleBeneficiaryQuery(loan?.id, {
    skip: fetch,
  });
  const loanDetails = data?.data ?? null;

  useEffect(() => {
    if (!state?.loanDetails) {
      navigate(`${Path.DASHBOARD_LOAN_BENEFICIARY}`);
    }
  }, [navigate, state]);

  useEffect(() => {
    if (loanDetails) {
      setFetch(true);
    }
  }, [loanDetails]);

  const handleConfirmRemoveBank = async () => {
    if (!loanDetails?.mandates || loanDetails.mandates.length <= 1) {
      toast({
        title: "Cannot Delete Mandate",
        description: "You cannot delete the last mandate. Mandates cannot be empty.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    const approvedMandates = loanDetails.mandates.filter(
      (mandate) => mandate.status === "approved"
    );

    if (approvedMandates.length === 1) {
      toast({
        title: "Cannot Delete Mandate",
        description: "You cannot delete the last approved mandate.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    try {
      if (selectedMandate) {
        const result = await cancelMandate({
          beneficiary: selectedMandate.beneficiary,
          account_id: selectedMandate.id,
        }).unwrap();

        toast({
          title: "Mandate Deleted",
          description: result?.message || "The mandate has been successfully deleted.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        onConfirmClose();
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error?.data?.message || "Failed to delete the mandate.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const name = loanDetails
    ? `${capitalize(loanDetails.first_name)} ${capitalize(loanDetails.last_name)}`
    : "";

  const OverlayOne = () => (
    <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(5px)" />
  );

  const renderContent = () => {
    if (isLoading || isFetching) {
      return (
        <Stack w="100%" minH="20rem">
          <Skeleton height="120px" />
          <Skeleton height="130px" />
          <Skeleton height="140px" />
        </Stack>
      );
    }

    if (!loanDetails) {
      return null;
    }

    return (
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing="24px"
        marginTop="24px"
      >
        {/* Profile Section */}
        <Stack w="35%" h="40rem" bg="white" padding="28px">
          <Stack w="100%">
            <Stack>
              <button
                onClick={() => navigate(-1)}
                className="w-250 flex items-center text-grey700"
              >
                <BackIcon />
                <span className="ml-8">Back to Overview</span>
              </button>
              <h2 className="font-inter font-semibold text-h6">
                {name}'s Profile
              </h2>
            </Stack>
            <Stack justifyContent="center" alignItems="center" mt="24px">
              <figure className="w-78 h-78 rounded-full bg-slate-500 mb-10" />
              <h5 className="font-inter font-semibold text-h6 text-blue200">
                {name}
              </h5>
              <h6 className="font-inter font-semibold text-12 text-[#425466]">
                <span className="text-[#75818D] text-12">User ID:</span> #{" "}
                {loanDetails?.id?.substring(0, 6)}
              </h6>
            </Stack>
            <Stack mt="24px">
              <UserDetails loanDetails={loanDetails} />
            </Stack>
          </Stack>
        </Stack>

        {/* Mandates Section */}
        <Stack w="65%" bg="white" padding="28px">
          <div className="flex items-center justify-between mb-17">
            <HStack flexBasis="40%" justifyContent="flex-start">
              <h5>
                Loan Beneficiary
                <Badge
                  marginLeft="8px"
                  backgroundColor="#F9F5FF"
                  borderRadius="16px"
                  padding="8px"
                  color="#6941C6"
                >
                  {loanDetails?.mandates?.length || 0} Beneficiary
                </Badge>
              </h5>
            </HStack>
          </div>

          <Stack w="100%" minH="20rem">
            {loanDetails?.mandates?.length > 0 ? (
              <section className="grid grid-cols-2 gap-16 my-16">
                {loanDetails.mandates.map((mandate) => (
                  <BankCard
                    key={mandate.id}
                    details={mandate}
                    bankimage={bankimage}
                    setSelectedMandate={setSelectedMandate}
                    onConfirmOpen={onConfirmOpen}
                    loanDetails={loanDetails}
                  />
                ))}
              </section>
            ) : (
              <section className="grid grid-cols-1 gap-16 my-16">
                <EmptyState title="No Mandate" />
              </section>
            )}

            <LoanListTable
              setFetch={setFetch}
              loanDetails={loanDetails}
            />
          </Stack>
        </Stack>
      </Stack>
    );
  };

  return (
    <DashboardLayout>
      <section className="mx-flush">
        {loanDetails && (
          <h2 className="font-inter font-medium text-black text-2xl">
            {name}'s Loans
          </h2>
        )}
        {renderContent()}
      </section>

      <MandateConfirmationModal
        header="Remove Mandate"
        text="You're trying to remove this from our service. Are you sure?"
        isOpen={isConfirmOpen}
        onClose={onConfirmClose}
        onConfirm={handleConfirmRemoveBank}
        ModalOverlay={OverlayOne}
      />
    </DashboardLayout>
  );
};

export default LoanList;