import DashboardLayout from "layouts/dashboard";
import { useState } from "react";
import EmptyState from "components/EmptyState";
import LoanCard from "components/LoanCard";
// import GraphCard from "components/GraphCard";
import TableContainer from "components/TableContainer";
import { useDisclosure } from "@chakra-ui/react";
import AddBeneficiaryModal from "./componenents/AddBenefeciary";
import BenefeciaryTable from "components/tables/BeneficiaryTable";
import { useGetAllBeneficiaryQuery } from "store/services/loan";
import { Stack, Skeleton, Spinner } from "@chakra-ui/react";
import { useGetLoanStatsQuery } from "store/services/business";
import { amountConverter } from "utils/strings";

const DashboardLoanBeneficiary = () => {
  const { data: loanStats, isLoading } = useGetLoanStatsQuery();



  const loanData = [
    {
      title: "Number of Beneficiaries",
      number: loanStats?.data?.number_beneficiaries,
      percent: "0",
      showFilter: false,
    },
    {
      title: "Recently Approved Loans",
      number: `₦${amountConverter(loanStats?.data?.approved)}`,
      percent: "0",
      showFilter: false,
    },
    {
      title: "Pending  Loans Approvals",
      number: `₦${amountConverter(loanStats?.data?.pending_loan)}`,
      percent: "0",
      showFilter: false,
    },
  ];

  const [skip] = useState(false);
  const [filter] = useState(`page=1`);

  const {
    data,
    isLoading: fetching,
    isFetching,
  } = useGetAllBeneficiaryQuery(filter, {
    skip: skip,
  });

  console.log(data, "loanbene");

  const {
    isOpen: isBeneficiaryOpen,
    onOpen: onBeneficiaryOpen,
    onClose: onBeneficiaryClose,
  } = useDisclosure();

  return (
    <DashboardLayout>
      <section className="mx-flush">
        <h2 className="font-inter font-medium text-black text-2xl">
          Loan Beneficiary
        </h2>

        {isLoading || isFetching ? (
          <section className="my-16">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </section>
        ) : (
          <section className="grid grid-cols-3 gap-16 my-16">
            {loanData?.map((_) => {
              return <LoanCard data={_} key={_?.title} />;
            })}
          </section>
        )}

        {/* <section className="grid grid-cols-2 gap-16 my-16">
          <GraphCard title="Loans" />
          <GraphCard title="Beneficiary" />
        </section> */}

        <section className="grid grid-cols-1 gap-16 my-16">
          {fetching || isFetching ? (
            <Stack>
              <Skeleton height="40px" />
              <Skeleton height="80px" />
              <Skeleton height="140px" />
            </Stack>
          ) : (
            <TableContainer
              title="Loan Beneficiary"
              count={data?.data?.length}
              handleRoute={() => onBeneficiaryOpen()}
              showBadge={true}
              showButton="true"

            >
              {data?.data?.length > 0 ? (
                <BenefeciaryTable beneficiaries={data?.data} />
              ) : (
                <section className="flex items-center justify-center min-h-300">
                  <EmptyState />
                </section>
              )}
            </TableContainer>
          )}
        </section>
      </section>
      <AddBeneficiaryModal
        isBeneficiaryOpen={isBeneficiaryOpen}
        onBeneficiaryOpen={onBeneficiaryOpen}
        onBeneficiaryClose={onBeneficiaryClose}
      />
    </DashboardLayout>
  );
};

export default DashboardLoanBeneficiary;
