import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Select,
  useToast,
  Textarea,
  Input,
  Tooltip,
} from "@chakra-ui/react";
import { Colors } from "utils/constant.data";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import { ReactComponent as EyeIcon } from "icons/eye.svg";
import DatePicker from "react-datepicker";
import { Plans, LoanTerms, LoanTermsWeekly, } from "utils/strings";
import { useCreateLoanMutation } from "store/services/loan";
import { differenceInMonths, differenceInYears, format } from "date-fns";
import { ReactComponent as TooltipI } from "icons/tooltipIcon.svg";

const FormField = ({ label, tooltipMessage, children, error, className = "auth-form-group !mb-16 basis-[48%]" }) => (
  <div className={className}>
    <div className="flex gap-6 items-center">
      <label className="form-label">{label}</label>
      <TooltipIcon message={tooltipMessage} />
    </div>
    {children}
    {error && <div className="error">{error}</div>}
  </div>
);
const DATE_FORMAT = "yyyy-MM-dd";
const DISPLAY_DATE_FORMAT = "dd/MM/yyyy";

// Utility functions
const formatCurrency = (value) => {
  if (!value) return "";
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const parseCurrencyToNumber = (value) => {
  if (!value) return 0;
  return parseFloat(value.replace(/,/g, "")) || 0;
};


const TooltipIcon = ({ message }) => (
  <Tooltip
    label={message}
    hasArrow
    placement="top"
    width="250px"
    left="50px"
    bg="white"
    color="black"
    border="1px solid #E2E8F0"
    borderRadius="md"
    p="2"
    boxShadow="md"
  >
    <span className="mt-[-4px] cursor"><TooltipI /></span>
  </Tooltip>
);
const AddLoanModal = ({ isOpen, onClose, id }) => {
  const toast = useToast();


  const OverlayOne = () => (
    <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(5px)" />
  );

  const BenefeciaryForm = () => {
    const [createBenefeciary, { isLoading: creating }] = useCreateLoanMutation();
    const [dates, setDates] = useState({
      start_date: null,
      end_date: null,
      direct_debit_start_date: null
    });

    const [loanAmount, setLoanAmount] = useState("");
    const [interestRate, setInterestRate] = useState("");
    const [selectedPlan, setSelectedPlan] = useState("");
    const [loanTerm, setLoanTerm] = useState("");

    const calculateLoanAmounts = useMemo(() => {
      const principal = parseCurrencyToNumber(loanAmount);
      const rate = parseFloat(interestRate);
      const term = parseInt(loanTerm, 10);

      if (isNaN(principal) || isNaN(rate) || isNaN(term)) {
        return { totalInterest: 0, totalAmount: 0 };
      }

      const totalInterest = ((principal * rate) / 100) * term;
      const totalAmount = totalInterest + principal;

      return {
        totalInterest: formatCurrency(totalInterest.toFixed(2)),
        totalAmount: formatCurrency(totalAmount.toFixed(2))
      };
    }, [loanAmount, interestRate, loanTerm]);

    const getLoanTermOptions = useMemo(() => {
      switch (selectedPlan) {
        case "Weekly": return LoanTermsWeekly;
        case "Monthly": return LoanTerms;
        case "Yearly": return LoanTerms;
        default: return [];
      }
    }, [selectedPlan]);

    const formik = useFormik({
      initialValues: {
        start_date: "",
        end_date: "",
        loan_amount: "",
        plan: "",
        loan_term: "",
        loan_purpose: "",
        interest_rate: "",
        direct_debit_start_date: "",
        direct_debit_amount: ""
      },
      validationSchema: LoanSchema,
      onSubmit: async (values, { resetForm }) => {
        try {
          const response = await createBenefeciary({
            ...values,
            id,
            start_date: format(dates.start_date, DATE_FORMAT),
            end_date: format(dates.end_date, DATE_FORMAT),
            direct_debit_start_date: format(dates.direct_debit_start_date, DATE_FORMAT),
            plan: values.plan.toLowerCase(),
            loan_term: values.loan_term.toLowerCase(),
            interest_rate: interestRate,
            total_interest: parseCurrencyToNumber(calculateLoanAmounts.totalInterest),
            total_amount: parseCurrencyToNumber(calculateLoanAmounts.totalAmount),
            additional_info: {}
          }).unwrap();

          toast({
            title: response?.message,
            description: response?.detail,
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top"
          });

          resetForm();
          onClose();
          window.location.reload();
        } catch (error) {
          toast({
            title: "Error Occurred",
            description: error?.data?.detail,
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top"
          });
        }
      },
      validate: (values) => {
        const errors = {};
        if (values.start_date && values.end_date) {
          const termInMonths = differenceInMonths(values.end_date, values.start_date);
          const termInYears = differenceInYears(values.end_date, values.start_date);

          let calculatedPlan = "Daily";
          if (termInYears >= 1) calculatedPlan = "Yearly";
          else if (termInMonths >= 1) calculatedPlan = "Monthly";

          if (calculatedPlan === "Daily" && (values.plan === "Monthly" || values.plan === "Yearly")) {
            errors.plan = "Selected plan exceeds the date range.";
          } else if (calculatedPlan === "Monthly" && values.plan === "Yearly") {
            errors.plan = "Selected plan exceeds the date range.";
          }
        }
        return errors;
      }
    });

    const handleDateChange = (date, field) => {
      setDates(prev => ({ ...prev, [field]: date }));
      formik.setFieldValue(field, format(date, DATE_FORMAT));
    };

    return (
      <form onSubmit={formik.handleSubmit} className="form !w-full mt-24">
        {/* Loan Start Date */}
        <FormField
          label="Loan Start Date"
          tooltipMessage="The date the loan agreement begins."
          error={formik.touched.start_date && formik.errors.start_date}
        >
          <DatePicker
            selected={dates.start_date}
            dateFormat={DISPLAY_DATE_FORMAT}
            icon={<EyeIcon />}
            showYearDropdown
            scrollableYearDropdown
            yearItemNumber={100}
            showMonthDropdown
            onChange={(date) => handleDateChange(date, "start_date")}
          />
        </FormField>

        {/* Loan End Date */}
        <FormField
          label="Loan End Date"
          tooltipMessage="The date when the loan is expected to be fully repaid."
          error={formik.touched.end_date && formik.errors.end_date}
        >
          <DatePicker
            selected={dates.end_date}
            dateFormat={DISPLAY_DATE_FORMAT}
            icon={<EyeIcon />}
            showYearDropdown
            showMonthDropdown
            onChange={(date) => handleDateChange(date, "end_date")}
          />
        </FormField>

        {/* Loan Amount */}
        <FormField
          label="Loan Amount"
          tooltipMessage="The total sum of money being borrowed under the loan agreement."
          error={formik.touched.loan_amount && formik.errors.loan_amount}
        >
          <Input
            variant="filled"
            bg="#EDEDED"
            placeholder="Loan Amount"
            height="60px"
            value={loanAmount}
            onChange={(e) => {
              const value = e.target.value.replace(/,/g, "");
              if (!isNaN(value)) {
                setLoanAmount(formatCurrency(value));
                formik.setFieldValue("loan_amount", value);
              }
            }}
          />
        </FormField>

        {/* Interest Rate */}
        <FormField
          label="Interest Rate (%)"
          tooltipMessage="The percentage charged on the loan amount as the cost of borrowing."
          error={formik.touched.interest_rate && formik.errors.interest_rate}
        >
          <Input
            variant="filled"
            placeholder="Interest Rate"
            value={formik.values.interest_rate}
            onChange={(e) => {
              const value = e.target.value;
              formik.setFieldValue("interest_rate", value);
              setInterestRate(parseFloat(value) || "");
            }}
          />
        </FormField>

        {/* Plan */}
        <FormField
          label="Plan"
          tooltipMessage="The repayment structure or schedule selected for the loan."
          error={formik.touched.plan && formik.errors.plan}
        >
          <Select
            placeholder="Choose Plan"
            variant="filled"
            bg="#EDEDED"
            height="60px"
            value={formik.values.plan}
            onChange={(e) => {
              const plan = e.target.value;
              setSelectedPlan(plan);
              formik.setFieldValue("plan", plan);
            }}
          >
            {Plans?.map(plan => (
              <option key={plan.value} value={plan.value}>
                {plan.label}
              </option>
            ))}
          </Select>
        </FormField>

        {/* Loan Term */}
        <FormField
          label="Loan Term"
          tooltipMessage="The total duration of the loan."
          error={formik.touched.loan_term && formik.errors.loan_term}
        >
          <Select
            placeholder="Choose Loan Term"
            variant="filled"
            bg="#EDEDED"
            height="60px"
            value={formik.values.loan_term}
            onChange={(e) => {
              const term = e.target.value;
              setLoanTerm(term);
              formik.setFieldValue("loan_term", term);
            }}
          >
            {getLoanTermOptions.map(term => (
              <option key={term.value} value={term.value}>
                {term.label}
              </option>
            ))}
          </Select>
        </FormField>

        {/* Total Amount */}
        <FormField
          label="Total Interest"
          tooltipMessage="The total interest amount over the loan period."
        >
          <Input
            isReadOnly
            value={calculateLoanAmounts.totalInterest}
            variant="filled"
            bg="#EDEDED"
            placeholder="Total Interest"
          />
        </FormField>
        {/*  */}
        <FormField
          label="Total Amount"
          tooltipMessage="The total loan amount including the principal and interest."
        >
          <Input
            isReadOnly
            value={calculateLoanAmounts.totalAmount}
            variant="filled"
            bg="#EDEDED"
            placeholder="Total Amount"
          />
        </FormField>

        {/* Direct Debit Start Date */}
        <FormField
          label="Direct Debit Start Date"
          tooltipMessage="The date when the automated loan repayment process will begin."
          error={formik.touched.direct_debit_start_date && formik.errors.direct_debit_start_date}
        >
          <DatePicker
            selected={dates.direct_debit_start_date}
            dateFormat={DISPLAY_DATE_FORMAT}
            icon={<EyeIcon />}
            showYearDropdown
            scrollableYearDropdown
            yearItemNumber={100}
            showMonthDropdown
            onChange={(date) => handleDateChange(date, "direct_debit_start_date")}
          />
        </FormField>

        {/* Direct Debit Amount */}
        <FormField
          label="Direct Debit Amount"
          tooltipMessage="The specific amount that will be automatically deducted for each repayment cycle."
          error={formik.touched.direct_debit_amount && formik.errors.direct_debit_amount}
        >
          <Input
            variant="filled"
            bg="#EDEDED"
            placeholder="Direct Debit Amount"
            height="60px"
            {...formik.getFieldProps("direct_debit_amount")}
          />
        </FormField>

        {/* loan purpose */}
        <div className="form-row-full auth-form-group !mb-16">
          <label htmlFor="Loan Purpose">Loan Purpose</label>
          <Textarea
            resize="none"
            placeholder="Loan Purpose"
            variant="filled"
            bg="#EDEDED"
            borderColor="transparent"
            borderRadius="0"
            color="#424242"
            height="60px"
            {...formik.getFieldProps("loan_purpose")}
          />
          {formik.touched.loan_purpose && formik.errors.loan_purpose ? (
            <div className="error">{formik.errors.loan_purpose}</div>
          ) : null}
        </div>


        <div className="flex my-12 basis-[100%]">
          <button type="submit" className="btn btn-primary w-full">
            {creating ? <Spinner color={Colors.white} /> : "Add"}
          </button>
        </div>
      </form>
    );
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <OverlayOne />
      <ModalContent>
        <ModalHeader borderBottom="1px solid #E0E0E0" padding="0">
          <div className="flex flex-col py-22 px-30">
            <h3 className="font-inter font-medium text-black text-2xl">
              Add Loan
            </h3>
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={4}>
          <BenefeciaryForm />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default AddLoanModal;




const LoanSchema = Yup.object().shape({
  start_date: Yup.string().required("Start Date is required"),
  end_date: Yup.string().required("End Date is required"),
  loan_amount: Yup.string().required("Loan Amount is required"),
  plan: Yup.string().required("Repayment Plan is required"),
  loan_term: Yup.string().required("Loan Term is required"),
  loan_purpose: Yup.string().required("Loan Purpose is required"),
  interest_rate: Yup.string()
    .required("Interest rate is required")
    .test("is-positive", "Interest rate cannot be zero", value => parseFloat(value) > 0),
  direct_debit_start_date: Yup.string().required("Direct Debit Start Date is required"),
  direct_debit_amount: Yup.string().required("Direct Debit Amount is required")
});