import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Input,
    useToast,
    Spinner,
    Select,
    FormControl,
    FormLabel,
    VStack,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Colors } from "utils/constant.data";
import { useCreateWitnessMutation } from 'store/services/loan';

const WitnessSchema = Yup.object().shape({
    witnessname: Yup.string().required("Guarantor Name is required"),
    witnessaddress: Yup.string().required("Guarantor address is required"),
    witnessoccupation: Yup.string().required("Guarantor occupation is required"),
    payersignature: Yup.string().required("Payer's Signature is required"),
    witnesssignature: Yup.string().required("Guarantor's Signature is required"),
});

const AddWitnessModal = ({ isWitnessOpen, onWitnessClose, id }) => {
    const [createWitness, { isLoading: creating }] = useCreateWitnessMutation();
    const toast = useToast();


    useEffect(() => {
        console.log("Modal opened with ID:", id);
    }, [id]);


    const [imgs, setImgs] = useState({
        witnesssignature: null,
        payersignature: null,
        witness: null,
        payer: null,
    });
    const [touched, setTouched] = useState({
        witnesssignature: false,
        payersignature: false,
    });

    const OverlayOne = () => (
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(5px)" />
    );

    const formik = useFormik({
        initialValues: {
            witnessname: "",
            witnessaddress: "",
            witnessoccupation: "",
            payersignature: "",
            witnesssignature: "",

        },
        validationSchema: WitnessSchema,
        onSubmit: async (values, { resetForm }) => {
            try {

                const response = await createWitness({
                    ...values,
                    id
                }).unwrap();

                console.log("Submitting witness data:", values);

                toast({
                    title: "Success",
                    description: "Guarantor added successfully",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                    position: "top"
                });

                resetForm();
                onWitnessClose();
            } catch (error) {
                toast({
                    title: "Error Occurred",
                    description: error?.message || "Failed to add guarantor",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: "top"
                });
            }
        }
    });

    return (
        <Modal
            closeOnOverlayClick={false}
            size="xl"
            isOpen={isWitnessOpen}
            onClose={onWitnessClose}
            isCentered
        >
            <OverlayOne />
            <ModalContent>
                <ModalHeader borderBottom="1px solid #E0E0E0" padding="0">
                    <div className="flex flex-col py-22 px-30">
                        <h3 className="font-inter font-medium text-black text-2xl">
                            Add Guarantor
                        </h3>
                    </div>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody p={4}>
                    <form onSubmit={formik.handleSubmit} className="form !w-full mt-24">
                        <div className="auth-form-group basis-[48%]">
                            <label htmlFor="email">Guarantor Name</label>
                            <input
                                type="text"
                                id="witnessname"
                                aria-describedby="Lastname"
                                placeholder="Enter your Guarantor's Name"
                                {...formik.getFieldProps("witnessname")}
                            ></input>
                            {formik.touched.witnessname && formik.errors.witnessname ? (
                                <div className="error">{formik.errors.witnessname}</div>
                            ) : null}
                        </div>

                        <div className="auth-form-group basis-[48%]">
                            <label htmlFor="email">Witness Address</label>
                            <input
                                type="text"
                                id="witnessaddres"
                                aria-describedby="Lastname"
                                placeholder="Enter Guarantor Address"
                                {...formik.getFieldProps("witnessaddress")}
                            ></input>
                            {formik.touched.witnessaddress && formik.errors.witnessaddress ? (
                                <div className="error">{formik.errors.witnessaddress}</div>
                            ) : null}
                        </div>

                        <div className="auth-form-group basis-[48%]">
                            <label htmlFor="email">Guarantor Occupation</label>
                            <input
                                type="text"
                                id="witnessoccupation"
                                aria-describedby="Lastname"
                                placeholder="Enter your Guarantor occupation"
                                {...formik.getFieldProps("witnessoccupation")}
                            ></input>
                            {formik.touched.witnessoccupation &&
                                formik.errors.witnessoccupation ? (
                                <div className="error">{formik.errors.witnessoccupation}</div>
                            ) : null}
                        </div>



                        <div className="auth-form-group basis-[48%]">
                            <label htmlFor="email">Payer's Signature</label>
                            <input
                                type="file"
                                id="payersignature"
                                // value={imgs?.payer}
                                aria-describedby="payersignature"
                                placeholder="Payers Signature"
                                onChange={(e) => {
                                    const file = e.target.files;
                                    // console.log("file payers name", file);
                                    setTouched({
                                        ...touched,
                                        payersignature: true,
                                    });

                                    if (file) {
                                        // console.log(e.target.files);
                                        const data = new FileReader();
                                        data.addEventListener("load", () => {
                                            setImgs({
                                                ...imgs,
                                                payer: file,
                                                payersignature: data.result,
                                            });
                                            formik.setFieldValue("payersignature", String(data.result));
                                        });
                                        data.readAsDataURL(e.target.files[0]);
                                    }
                                }}
                            ></input>
                            {touched.payersignature &&
                                !imgs?.payersignature && (
                                    <div className="error">Payer's Signature required</div>
                                )}
                        </div>

                        <div className="auth-form-group basis-[48%]">
                            <label htmlFor="email">Guarantor's Signature</label>
                            <input
                                type="file"
                                id="witnesssignature"
                                aria-describedby="Lastname"
                                placeholder="Witness Signature"
                                // value={imgs?.witness}
                                onChange={(e) => {
                                    const file = e.target.files;
                                    // console.log("file witness", file);

                                    setTouched({
                                        ...touched,
                                        witnesssignature: true,
                                    });

                                    if (file) {
                                        const data = new FileReader();
                                        data.addEventListener("load", () => {
                                            setImgs({
                                                ...imgs,
                                                witness: file,
                                                witnesssignature: data.result,
                                            });
                                            formik.setFieldValue("witnesssignature", String(data.result));
                                        });
                                        data.readAsDataURL(e.target.files[0]);
                                    }
                                }}
                            ></input>

                            {touched.witnesssignature &&
                                !imgs?.witnesssignature && (
                                    <div className="error">Witness's Signature required</div>
                                )}
                        </div>

                        <div className="flex my-12 basis-[100%] w-full">
                            <button type="submit" className="btn btn-primary w-full">
                                {formik.isSubmitting ? <Spinner color={Colors.white} /> : "Add Guarantor"}
                            </button>
                        </div>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default AddWitnessModal;