import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  useDisclosure,
} from "@chakra-ui/react";
import { Colors } from "utils/constant.data";
import { format } from "date-fns";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import { useMemo } from "react";
import EmptyState from "components/EmptyState";
import { capitalize, formatUnderScore } from "utils/strings";
import { mapBadge } from "utils/strings";
import MemberDetailsModal from "pages/dashbaord/team/componenents/TeamDetails";

const TeamTable = ({ transactions, metadata }) => {
  const COLUMNS = (ee) => [
    {
      Header: "Email",
      accessor: "email",
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return (
          <Td width="15%" padding="0" border="0">
            {/* <h6>{value ? value.substring(0, 10) : "Null"}</h6> */}
            <h6>{value ? capitalize(value) : "Null"}</h6>
          </Td>
        );
      },
    },
    {
      Header: "Permission",
      accessor: "permission",
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return (
          <Td border="0" pl="0">
            <h6>{capitalize(formatUnderScore(value))}</h6>
          </Td>
        );
      },
    },

    {
      Header: "Status",
      accessor: "is_active",
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return (
          <Td border="0" pl="0">
            <Badge variant="solid" colorScheme={mapBadge(value ? "active" : "inactive")}>
              {value ? "Active" : "InActive"}
            </Badge>
          </Td>
        );
      },
    },
    {
      Header: "Created At",
      accessor: "created_at",
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return (
          <Td border="0" pl="0">
            <h6>{format(new Date(value), "dd MMM YYY")}</h6>
          </Td>
        );
      },
    },
    {
      Header: "",
      accessor: "id",
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        const { isOpen, onOpen, onClose } = useDisclosure();
        return (
          <Td border="0">
            <button
              onClick={() => {
                onOpen();
              }}
              className="cursor-pointer text-primary font-bold"
            >
              View User
            </button>

            <MemberDetailsModal
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              details={cell?.row?.original}
            />
          </Td>
        );
      },
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => COLUMNS("ee"), []);

  const tableInstance = useTable(
    { columns, data: transactions, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <section className="h-[25rem] overflow-y-scroll">
      {transactions && transactions?.length > 0 ? (
        <Table
          variant="simple"
          bgColor={Colors.white}
          mt="2rem"
          {...getTableProps()}
        >
          <Thead className="dashboard__table__header">
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    scope="col"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Tr padding="0" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Td pt="0" pb="0" {...cell.getCellProps()}>
                        {" "}
                        {cell.render("Cell")}{" "}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : (
        <EmptyState />
      )}
    </section>
  );
};

export default TeamTable;
