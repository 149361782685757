import {
  Stack,
  Skeleton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import EmptyState from "components/EmptyState";
import { capitalize } from "utils/strings";
import { Colors } from "utils/constant.data";
import TableContainer from "components/TableContainer";
import StepTwo from "../onboarding/StepTwo";
import BlurOverlay from "layouts/components/BlurOverlay";
import MandateConfirmationModal from "components/MandateConfirmationModal";
import { useState } from "react";
import { useGetAllBankAccountQuery, useDeleteBankAccountMutation } from "store/services/business";

const BankAccount = () => {
  const toast = useToast();
  const { data, isLoading, refetch } = useGetAllBankAccountQuery();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const account = data?.results || [];
  const [deleteAccount] = useDeleteBankAccountMutation();
  const [selectedMandate, setSelectedMandate] = useState(null);

  const handleDisableMandate = async () => {
    if (account?.length <= 1) {
      toast({
        title: "Cannot Disable only account",
        description: "You cannot delete the last Account. Account cannot be empty.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    try {
      if (selectedMandate) {
        const result = await deleteAccount({
          id: selectedMandate.id,
          account_id: selectedMandate.id,
        }).unwrap();

        toast({
          title: "Account Deleted",
          description: result?.message || "The account has been deleted.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });

        refetch();
        onConfirmClose();
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error?.data?.message || "Failed to delete the account.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleAddAccount = async () => {

    refetch();
    onClose();
  };

  return (
    <Stack>
      <section>
        <Stack>
          {isLoading ? (
            <Stack>
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </Stack>
          ) : (
            <div className="px-24 pb-24 bg-white">
              <TableContainer
                title="Bank Account"
                count={account?.length}
                handleRoute={onOpen}
                showBadge={true}
                showNew={true}
                btnText="Add Account"
                style={{ height: "100vh" }}
              >
                {account?.length > 0 ? (
                  <Table variant="simple" bgColor={Colors.white}>
                    <Thead className="dashboard__table__header">
                      <Tr>
                        <Th>Bank</Th>
                        <Th>Account Number</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody className="dashboard__table__body">
                      {account.map((acc) => (
                        <Tr key={acc.id}>
                          <Td>
                            <h6>{capitalize(acc.bank)}</h6>
                          </Td>
                          <Td>
                            <h6>{acc.account_number}</h6>
                          </Td>
                          <Td>
                            <button
                              className="cursor-pointer text-primary font-bold"
                              onClick={() => {
                                setSelectedMandate(acc);
                                onConfirmOpen();
                              }}
                            >
                              Delete
                            </button>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                ) : (
                  <section className="flex items-center justify-center min-h-300 bg-white">
                    <EmptyState title="No Accounts found" />
                  </section>
                )}
              </TableContainer>
            </div>
          )}
        </Stack>

        {/* Modal for StepTwo */}
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
          <BlurOverlay />
          <ModalContent>
            <ModalHeader>Add Bank Account</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <StepTwo setStep={handleAddAccount} />
            </ModalBody>
          </ModalContent>
        </Modal>

        {/* Mandate Confirmation Modal */}
        <MandateConfirmationModal
          header="Delete Bank Account"
          text="You’re trying to Delete this from our service. Are you sure?"
          isOpen={isConfirmOpen}
          onClose={onConfirmClose}
          onConfirm={handleDisableMandate}
        />
      </section>
    </Stack>
  );
};

export default BankAccount;



