import DashboardLayout from "layouts/dashboard";
import { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  HStack,
} from "@chakra-ui/react";
import { Colors, Path } from "utils/constant.data";
import { Select } from "@chakra-ui/react";
import {
  useCreditCheckMutation,
  useGetAllChecksQuery,
  useRerunCreditCheckMutation,
} from "store/services/business";
import { useNavigate } from "react-router-dom";
import { useToast, Spinner, Skeleton, Stack } from "@chakra-ui/react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { checkTypes, countries, getCountryByCode } from "utils/strings";
import { NumberInput, NumberInputField } from "@chakra-ui/react";
import EmptyState from "components/EmptyState";
import { capitalize } from "utils/strings";
import CreditDetails from "./components/creditDetails";
import { ReactComponent as ArrowUpIcon } from "icons/arrowUpSM.svg";
import { ReactComponent as ArrowDownIcon } from "icons/arrowDownSM.svg";
import { ReactComponent as RefreshIcon } from "icons/refresh.svg";
import axios_instance from "store/constant/axiosInstance";
import { format } from "date-fns";

const DashboardCreditCheck = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isResultOpen,
    onOpen: onResultOpen,
    onClose: onResultClose,
  } = useDisclosure();
  const { data, isLoading } = useGetAllChecksQuery();

  const OverlayOne = () => (
    <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(5px)" />
  );

  const [selectedFile, setSelectedFile] = useState(null);
  const [tab, setTab] = useState("single");
  const [loading, setLoading] = useState(null);
  const [overlay] = useState(<OverlayOne />);
  const [active, setActive] = useState("");
  const [activeID, setID] = useState(null);
  const [defaultAnalysis, setDefaultAnalysis] = useState("");
  const toast = useToast();

  const [creditCheck, { isLoading: checking, isFetching }] =
    useCreditCheckMutation();
  const [rerunCreditCheck, { isLoading: rerunning }] =
    useRerunCreditCheckMutation();

  const handleRerun = async (analysis_id) => {
    await rerunCreditCheck({
      analysis_id,
    })
      .unwrap()
      .then((data) => {
        toast({
          title: "Success",
          description: "Check Requested Again",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        window.location.reload();
      })
      .catch((error) => {
        toast({
          title: "Error Occured",
          description: error?.data?.detail,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const handleSubmit = async (values, { resetForm }) => {
    await creditCheck({
      ...values,
    })
      .unwrap()
      .then((data) => {
        toast({
          title: "Success",
          description: "Check Requested",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        resetForm();
        setActive({
          request_data: { ...values },
          response_data: { ...data?.data },
        });
        onClose();
        onResultOpen();
      })
      .catch((error) => {
        toast({
          title: "Error Occured",
          description: error?.data?.detail,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      country: "",
      check_type: "",
      idnumber: "",
      // income: "",
      // loan: ""
    },
    validationSchema: CreditSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleDownload = () => {
    const downloadUrl =
      process.env.PUBLIC_URL + "/generated_excel_file_credit.xlsx";
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "credit_check_template.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast({
      title: "Success",
      description: `✔️ Sample file downloaded!`,
      status: "success",
      duration: 9000,
      isClosable: true,
      position: "top",
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedFileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];

    if (file) {
      const fileType = file.type;
      if (allowedFileTypes.includes(fileType)) {
        setSelectedFile(file);
      } else if (file.size > 10 * 1024 * 1024) {
        toast({
          title: "Error Occured",
          description: "❌ File size exceeds the 2MB limit.!",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        setSelectedFile(null);
      } else {
        toast({
          title: "Error Occured",
          description: "❌ Only xls files are allowed. !",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        setSelectedFile(null);
      }
    }
  };

  const handleBulk = async () => {
    if (selectedFile) {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      try {
        await axios_instance.post(`merchant/peform/credit/bulk`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        toast({
          title: "Upload Succesful",
          description: `✔️ Bulk-Credit Check uploaded!`,
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        setLoading(false);
        onClose();
      } catch (error) {
        toast({
          title: "Error Occured",
          description: "❌ Error uploading file. !",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        setLoading(false);
      }
    } else {
      toast({
        title: "Error Occured",
        description: "❌ Error uploading file. !",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <DashboardLayout>
      <section className="mx-flush">
        <div className="flex items-center justify-between my-20">
          <h2 className="font-inter font-medium text-black text-2xl">
            Credit Analysis
          </h2>

          <button onClick={onOpen} className="btn btn-primary !px-20">
            Perform Credit Analysis
          </button>
          {/* <button onClick={onResultOpen} className="btn btn-primary !px-20">
            open Check
          </button> */}
        </div>

        <Stack>
          {isLoading || isFetching ? (
            <Stack>
              <Skeleton height="20px" />
              <Skeleton height="30px" />
              <Skeleton height="40px" />
              <Skeleton height="50px" />
              <Skeleton height="60px" />
              <Skeleton height="70px" />
            </Stack>
          ) : data?.results?.length > 0 ? (
            <TableContainer>
              <Table variant="simple" bgColor={Colors.white}>
                <Thead className="dashboard__table__header">
                  <Tr>
                    <Th>Type</Th>
                    <Th>Country</Th>
                    <Th>Means of Identification</Th>
                    <Th>Score</Th>
                    <Th>Analysis Date</Th>
                    <Th>Status</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody className="dashboard__table__body">
                  {data?.results?.map((_) => {
                    return (
                      <Tr key={_?.id}>
                        <Td>
                          <h6>{capitalize(_?.request_data?.check_type)}</h6>
                        </Td>
                        <Td>
                          {" "}
                          <h6>{getCountryByCode(_?.request_data?.country)}</h6>
                        </Td>
                        <Td>
                          {" "}
                          <h6>{_?.request_data?.idnumber}</h6>
                        </Td>
                        <Td>
                          {" "}
                          <h6>{_?.response_data?.credit_score?.score}</h6>
                        </Td>
                        <Td>
                          {" "}
                          <h6>
                            {format(new Date(_?.created_at), "dd MMM YYY")}
                          </h6>
                        </Td>
                        <Td>
                          <div
                            className={`badge-container ${_?.status ? "success" : "danger"
                              }`}
                          >
                            <span></span>
                            <p>{_?.status ? "Active" : "Inactive"}</p>
                          </div>
                        </Td>
                        <Td>
                          <div className="flex items-center">
                            <button
                              onClick={() => {
                                setActive({ ..._ });
                                onResultOpen();

                                navigate(
                                  `${Path.DASHBOARD_CREDIT_CHECK}/details`,
                                  {
                                    state: {
                                      checkDetails: _,
                                    },
                                  }
                                );
                              }}
                              className="cursor-pointer btn btn-bordered mr-8"
                            >
                              View Result
                            </button>
                            <button
                              onClick={() => {
                                setID(_?.id);
                                handleRerun(_?.id);
                              }}
                              className={`transition-all ${activeID === _?.id && rerunning
                                ? "animate-spin-run"
                                : ""
                                }`}
                            >
                              {activeID === _?.id && rerunning ? (
                                <Spinner />
                              ) : (
                                <RefreshIcon />
                              )}
                            </button>
                          </div>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          ) : (
            <section className="flex items-center justify-center min-h-300 bg-white">
              <EmptyState title="No Credit Checks" />
            </section>
          )}
        </Stack>
      </section>

      <Modal
        closeOnOverlayClick={false}
        size="xl"
        isOpen={isOpen}
        onClose={onClose}
      >
        {overlay}
        <ModalContent>
          <ModalHeader>
            <div className="flex flex-col">
              <h3>Perform Credit Analysis</h3>
              <p>Get instant credit and risk analysis for your clients</p>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack p="24px" justifyContent="space-between">
              <button
                onClick={() => setTab("single")}
                className={`btn ${tab === "single" ? "btn-primary" : "btn-grey"
                  }  !basis-[48%]`}
              >
                {" "}
                Single Upload
              </button>
              <button
                onClick={() => setTab("bulk")}
                className={`btn ${tab === "bulk" ? "btn-primary" : "btn-grey"
                  }  !basis-[48%]`}
              >
                Bulk Upload
              </button>
            </HStack>

            {tab === "single" && (
              <div className=" flex items-center mb-24">
                <form
                  onSubmit={formik.handleSubmit}
                  className={`form w-full mt-24 ${checking ? "disabled" : ""}`}
                >
                  <div className="form-row-full auth-form-group !mb-16">
                    <label htmlFor="email">Country</label>

                    <Select
                      placeholder="Choose Country"
                      variant="filled"
                      bg="#EDEDED"
                      borderColor="transparent"
                      borderRadius="0"
                      color="#424242"
                      height="60px"
                      {...formik.getFieldProps("country")}
                    >
                      {countries?.map((_) => {
                        return <option value={_?.code}>{_?.country}</option>;
                      })}
                    </Select>

                    {formik.touched.country && formik.errors.country ? (
                      <div className="error">{formik.errors.country}</div>
                    ) : null}
                  </div>

                  <div className="form-row-full auth-form-group !mb-16">
                    <label htmlFor="email">Check Type</label>

                    <Select
                      placeholder="Choose Type"
                      variant="filled"
                      bg="#EDEDED"
                      borderColor="transparent"
                      borderRadius="0"
                      color="#424242"
                      height="60px"
                      {...formik.getFieldProps("check_type")}
                    >
                      {checkTypes?.map((_) => {
                        return <option value={_?.value}>{_?.label}</option>;
                      })}
                    </Select>

                    {formik.touched.check_type && formik.errors.check_type ? (
                      <div className="error">{formik.errors.check_type}</div>
                    ) : null}
                  </div>

                  <div className="form-row-full auth-form-group !mb-16">
                    <label htmlFor="email">ID Number</label>

                    <NumberInput>
                      <NumberInputField
                        {...formik.getFieldProps("idnumber")}
                        variant="filled"
                        bg="#EDEDED"
                        borderColor="transparent"
                        borderRadius="0"
                        color="#424242"
                        placeholder="ID Number"
                        height="60px"
                      />
                    </NumberInput>

                    {formik.touched.idnumber && formik.errors.idnumber ? (
                      <div className="error">{formik.errors.idnumber}</div>
                    ) : null}
                  </div>

                  <div
                    className="flex justify-start mt-6"
                    onClick={() => setDefaultAnalysis(!defaultAnalysis)}
                  >
                    <div className="grow w-full">
                      <label className=" pt-10 text-sm font-urbanist font-bold text-slate-600">
                        Want to perform a credit default Analysis?
                      </label>
                    </div>
                    {!defaultAnalysis && <ArrowDownIcon className="m-10" />}
                    {defaultAnalysis && <ArrowUpIcon className="m-10" />}
                  </div>

                  {defaultAnalysis && (
                    <div className="form-row-full auth-form-group !mb-16">
                      <label htmlFor="email">Estimated Income (optional)</label>
                      <NumberInput>
                        <NumberInputField
                          {...formik.getFieldProps("income")}
                          variant="filled"
                          bg="#EDEDED"
                          borderColor="transparent"
                          borderRadius="0"
                          color="#424242"
                          placeholder="Income"
                          height="60px"
                        />
                      </NumberInput>
                    </div>
                  )}

                  {defaultAnalysis && (
                    <div className="form-row-full auth-form-group !mb-16">
                      <label htmlFor="email">Loan Amount (optional)</label>
                      <NumberInput>
                        <NumberInputField
                          {...formik.getFieldProps("loan")}
                          variant="filled"
                          bg="#EDEDED"
                          borderColor="transparent"
                          borderRadius="0"
                          color="#424242"
                          placeholder="Amount"
                          height="60px"
                        />
                      </NumberInput>
                    </div>
                  )}

                  <div className="form-row-half flex my-12"></div>
                  <div className="form-row-half flex my-12">
                    <button type="submit" className={`btn btn-primary w-full`}>
                      {checking ? (
                        <Spinner color={Colors.white} />
                      ) : (
                        "Perform Credit Analysis"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            )}

            {tab === "bulk" && (
              <Stack padding="24px">
                <div className="flex flex-col form-row-full auth-form-group mb-3">
                  <label
                    htmlFor="progressFile"
                    className="!text-base !font-medium !mb-8"
                  >
                    Upload Check Sheet (Excel)
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    id="progressFile"
                    accept=".xls, .xlsx, .csv"
                    onChange={handleFileChange}
                  />

                  {selectedFile && (
                    <div className="mb-3">
                      <label htmlFor="progressFile" className="form-label">
                        Selected File : {selectedFile?.name}
                      </label>
                    </div>
                  )}

                  <div className="mt-20 flex justify-between">
                    <button
                      type="button"
                      className="btn btn-grey basis-[48%]"
                      onClick={handleDownload}
                    >
                      Download Sample
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary  basis-[48%]"
                      disabled={selectedFile ? false : true}
                      onClick={() => handleBulk()}
                    >
                      {loading ? (
                        <Spinner color={Colors.white} />
                      ) : (
                        "Perform Bulk Check"
                      )}
                    </button>
                  </div>
                </div>
              </Stack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <CreditDetails
        isResultOpen={isResultOpen}
        onResultOpen={onResultOpen}
        onResultClose={onResultClose}
        active={active}
      />
    </DashboardLayout>
  );
};

export default DashboardCreditCheck;

const CreditSchema = Yup.object().shape({
  country: Yup.string().required("Country is required"),
  check_type: Yup.string().required("Check type is required"),
  idnumber: Yup.string().required("ID is required"),
});
