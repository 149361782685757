/** @format */

export const Path = {
  LOGIN: "/auth/login",
  REGISTER: "/auth/register",
  CONFIRM_ACCOUNT: "/auth/verify-otp",
  SET_PACKAGE: "/auth/set-package",
  FORGOT_PASSWORD: "/auth/forgot-password",
  RESET_PASSWORD: "/auth/reset-password",
  DASHBOARD_OVERVIEW: "/dashboard/overview",
  DASHBOARD_ONBOARDING: "/dashboard/onboarding",
  DASHBOARD_CREDIT_CHECK: "/dashboard/credit-check",
  DASHBOARD_CREDIT_CHECK_DETAILS: "/dashboard/credit-check/details",
  DASHBOARD_SETTINGS: "/dashboard/settings",
  DASHBOARD_LOAN_BENEFICIARY: "/dashboard/loan-beneficiary",
  LOAN_DETAILS: "/dashboard/loan-details",
  DASHBOARD_LOAN_DETAILS: "/dashboard/loan-details/:loanId",
  LOAN_LIST: "/dashboard/loan-list",
  DASHBOARD_LOAN_LIST: "/dashboard/loan-list/:loanId",
  DASHBOARD_TRANSACTIONS: "/dashboard/transactions",
  DASHBOARD_AUDIT_LOGS: "/dashboard/audit-logs",
  DASHBOARD_BLACKLIST: "/dashboard/blacklist",
  // DASHBOARD_SWEEP: "/dashboard/automated-sweep",
  DASHBOARD_SWEEP: "/dashboard/onboarding",
  DASHBOARD_WALLET: "/dashboard/wallet",
  DASHBOARD_API_SDK: "/dashboard/apiSdk",
  DASHBOARD_WALLET_SUCCESS: "/dashboard/wallet/funding/sucesss",
  DASHBOARD_TEAM_MANAGEMENT: "/dashboard/team-management",
};

export const Colors = {
  white: "white",
  primary: "#33B469",
  brand_blue: "#26205E",
  blue200: "#2F80ED",
  dark100: "#010E0E",
  dark200: "#424242",
  dark300: "#53565A",
  grey100: "#EDEDED",
  grey200: "#E1E1E1",
  grey300: "#FBFBFD",
  grey500: "#F5F5F5",
  grey650: "#EAECF0",
  purple100: "#5045E6",

  blue100: "#0063F7",
  dark400: "#101828",
  grey400: "#E0E0E0",
  grey600: "#505D6F",
  grey800: "#667085",
};
