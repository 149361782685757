/** @format */

import { createApi } from "@reduxjs/toolkit/query/react";
import { API_TAGS } from "utils/strings";
import baseQueryWithReauth from "./baseQuery";

export const businessApi = createApi({
  reducerPath: "businessApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    API_TAGS.PERFORM_CREDIT_CHECK,
    API_TAGS.GET_CREDIT_CHECKS,
    API_TAGS.GET_ALL_BANK_ACCOUNTS,
    API_TAGS.ADD_BANK_ACCOUNT,
    API_TAGS.UPDATE_BANK_ACCOUNT,
    API_TAGS.DELETE_BANK_ACCOUNT,
  ],
  endpoints: (builder) => ({
    creditCheck: builder.mutation({
      query: (body) => {
        return {
          url: `merchant/peform/credit`,
          method: "POST",
          body,
        };
      },
      providesTags: [API_TAGS.PERFORM_CREDIT_CHECK],
      // invalidatesTags: [API_TAGS.GET_DOCUMENTS],
    }),
    rerunCreditCheck: builder.mutation({
      query: (body) => {
        return {
          url: `merchant/peform/credit/rerun`,
          method: "POST",
          body,
        };
      },
      providesTags: [API_TAGS.RERUN_CREDIT_CHECK],
      invalidatesTags: [API_TAGS.GET_CREDIT_CHECKS],
    }),
    getAllChecks: builder.query({
      query: () => `merchant/check/log`,
      providesTags: [API_TAGS.GET_CREDIT_CHECKS],
    }),

    getAllBankAccount: builder.query({
      query: () => `merchant/bank-accounts/list`,
      providesTags: [API_TAGS.GET_ALL_BANK_ACCOUNTS],
    }),
    addBankAccount: builder.mutation({
      query: (body) => {
        return {
          url: `merchant/bank-accounts/add`,
          method: "POST",
          body,
        };
      },
      providesTags: [API_TAGS.ADD_BANK_ACCOUNT],
    }),
    updateBankAccount: builder.mutation({
      query: (body) => ({
        url: `merchant/bank-accounts/${body.account_id}/update`,
        method: "PUT",
        body,
      }),
      providesTags: [API_TAGS.UPDATE_BANK_ACCOUNT],
    }),
    validateBankAccount: builder.mutation({
      query: (body) => ({
        url: `merchant/bank-accounts/validate`,
        method: "POST",
        body,
      }),
    }),

    deleteBankAccount: builder.mutation({
      query: (body) => ({
        url: `merchant/bank-accounts/${body?.id}/delete`,
        // http://127.0.0.1:8000/api/v1/merchant/bank-accounts/081ee431-e4c2-42ac-904d-b44f82fd5453/delete
        method: "DELETE",
      }),
      providesTags: [API_TAGS.DELETE_BANK_ACCOUNT],
    }),



    getAllTransations: builder.query({
      query: () => `merchant/transactions`,
      providesTags: [API_TAGS.GET_ALL_TRANSACTIONS],
    }),

    getBlacklist: builder.query({
      query: () => `blacklist/list`,
      providesTags: [API_TAGS.GET_BLACKLIST],
    }),
    addBlacklist: builder.mutation({
      query: (body) => {
        return {
          url: `blacklist/add`,
          method: "POST",
          body,
        };
      },
      providesTags: [API_TAGS.ADD_TO_BLACKLIST],
    }),
    updateBlacklist: builder.mutation({
      query: (body) => ({
        url: `merchant/bank-accounts/${body.account_id}/update`,
        method: "PUT",
        body,
      }),
      providesTags: [API_TAGS.UPDATE_BLACKLIST],
    }),
    deleteBlacklist: builder.mutation({
      query: (body) => ({
        url: `blacklist/${body?.id}/delete`,
        method: "DELETE",
      }),
      providesTags: [API_TAGS.DELETE_FROM_BLACKLIST],
    }),

    getDashboardStats: builder.query({
      query: () => `merchant/dashboard/stats`,
    }),
    getLoanStats: builder.query({
      query: () => `merchant/loan-beneficiary-stats`,
    }),
    getTransactionStats: builder.query({
      query: () => `merchant/transactions/analytics`,
    }),

    getSweepSettings: builder.query({
      query: () => `merchant/sweep`,
      // providesTags: [API_TAGS.GET_BLACKLIST],
    }),
    getSweepTransactions: builder.query({
      query: () => `merchant/sweep/transactions`,
      // providesTags: [API_TAGS.GET_BLACKLIST],
    }),
    createSweepSettings: builder.mutation({
      query: (body) => {
        return {
          url: `merchant/sweep/settings`,
          method: "POST",
          body,
        };
      },
    }),

    getMerchantProfile: builder.query({
      query: () => `merchant/profile/get`,
    }),

    getReGenerateKey: builder.query({
      query: () => `merchant/profile/regenerate_keys`,
    }),

    // webhook
    // merchant/webhook/31da4428-b74a-49eb-82c0-0cd112169b9d/delete
    createWebhook: builder.mutation({
      query: (body) => {
        return {
          url: `merchant/webhook/add`,
          method: "POST",
          body
        }
      }
    }),

    getWebhok: builder.query({
      query: () => `merchant/webhook/list`,
    }),

    updateWebhook: builder.mutation({
      query: (body) => {
        return {
          url: `merchant/webhook/${body.id}/update-status`,
          method: "POST",
          body,
        };
      },
    }),

    deleteWebhook: builder.mutation({
      query: (id) => ({
        url: `merchant/webhook/${id}/delete`,
        method: "GET",
      }),
      providesTags: [API_TAGS.DELETE_WEBHOOK],

    }),
    updateMerchantProfile: builder.mutation({
      query: (body) => {
        return {
          url: `merchant/profile/update`,
          method: "POST",
          body,
        };
      },
    }),

    updateLoanStatus: builder.mutation({
      query: (body) => {
        return {
          url: `merchant/loan/update`,
          method: "POST",
          body,
        };
      },
    }),


  }),
});

export const {
  useCreditCheckMutation,
  useRerunCreditCheckMutation,
  useGetAllChecksQuery,
  useGetAllBankAccountQuery,
  useAddBankAccountMutation,
  useUpdateBankAccountMutation,
  useDeleteBankAccountMutation,
  useValidateBankAccountMutation,


  useGetAllTransationsQuery,

  useGetBlacklistQuery,
  useAddBlacklistMutation,
  useUpdateBlacklistMutation,
  useDeleteBlacklistMutation,

  useGetDashboardStatsQuery,

  useGetSweepSettingsQuery,
  useCreateSweepSettingsMutation,
  useGetSweepTransactionsQuery,

  useGetLoanStatsQuery,
  useGetTransactionStatsQuery,

  useGetMerchantProfileQuery,
  useLazyGetReGenerateKeyQuery,
  useCreateWebhookMutation,
  useLazyGetWebhokQuery,
  useUpdateWebhookMutation,
  useDeleteWebhookMutation,
  useUpdateMerchantProfileMutation,
  useUpdateLoanStatusMutation,
} = businessApi;
