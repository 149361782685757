import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Text,
} from "@chakra-ui/react";

const MandateConfirmationModal = ({ isOpen, onClose, onConfirm, header, text }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay bg="blackAlpha.600" />
            <ModalContent>
                <ModalHeader>{header}</ModalHeader>
                <ModalBody>
                    <Text>
                        {text}
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="red" onClick={onConfirm}>
                        Yes
                    </Button>
                    <Button variant="ghost" onClick={onClose} ml={3}>
                        No
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default MandateConfirmationModal;
