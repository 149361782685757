import React, { useEffect, useState } from 'react';
import {
    Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton,
    ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack,
    Switch, useDisclosure, useToast
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useFormik } from 'formik';
import bankBackground from "../../../../image/bankBackground.png";
import { useSelectMandateMutation, useSendMandateConsentMutation } from 'store/services/loan';



export const getUserDetails = (loanDetails) => [
    {
        label: "First Name",
        value: loanDetails?.first_name,
    },
    {
        label: "Last Name",
        value: loanDetails?.last_name,
    },
    {
        label: "Gender",
        value: loanDetails?.gender ?? "Male",
    },
    {
        label: "Date of Birth",
        value: loanDetails?.dob,
    },
    {
        label: "BVN",
        value: loanDetails?.bvn,
    },
    {
        label: "Status",
        value: loanDetails?.is_active ? "Active" : "Not Active",
        badge: true,
    },
];


export const UserDetails = ({ loanDetails }) => {
    const details = getUserDetails(loanDetails);
    console.log(loanDetails, "lllll");

    return (
        <Stack mt="24px">
            {details?.map((detail, index) => (
                <div key={index} className="flex flex-row justify-between items-center border-b pt-4 pb-12 text-grey950">
                    <h6 className="font-inter font-normal text-sm text-grey950">
                        {detail?.label}
                    </h6>
                    <h6 className="font-inter font-normal text-sm text-[#181830]">
                        {detail?.value}
                    </h6>
                </div>
            ))}
        </Stack>
    );
};

export const BankCard = ({
    details,
    bg = "#826AF9",
    bankimage,
    setSelectedMandate,
    onConfirmOpen,
    loanDetails
}) => {
    const matchingBank = Array.isArray(bankimage)
        ? bankimage.find(bank =>
            bank?.name?.toLowerCase() === details?.bankname?.toLowerCase()
        )
        : null;

    const CurrentIcon = matchingBank?.icon || null;
    const currentBg = matchingBank?.bg || bg;

    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isSuccessModalOpen,
        onOpen: onSuccessModalOpen,
        onClose: onSuccessModalClose
    } = useDisclosure();
    const [successMessage, setSuccessMessage] = useState("");
    const toast = useToast();
    const witnesses = loanDetails?.witnesses || [];
    const [selectMandate, { isLoading }] = useSelectMandateMutation();
    const [sendMandateConsent] = useSendMandateConsentMutation()
    console.log(loanDetails, "lllll");
    useEffect(() => {
        if (details?.id) {
            setSelectedMandate(details);
        }
    }, [details, setSelectedMandate]);

    const WitnessSchema = Yup.object().shape({
        amount: Yup.number().required('Amount is required').positive('Amount must be positive'),
        type: Yup.string().required('Type is required'),
    });



    const formik = useFormik({
        initialValues: {
            amount: '',
            type: '',
            witness: '',
            account_id: details?.id,
        },
        validationSchema: WitnessSchema,
        onSubmit: async (values) => {
            try {
                const response = await selectMandate({
                    id: loanDetails?.id,
                    account_id: details?.id,
                    amount: values.amount,
                    type: values.type,
                    ...(values.type === "manual" && { witness: values.witness }),
                }).unwrap();

                if (values.type === 'e-mandate') {
                    setSuccessMessage(response?.data?.message || 'Mandate details submitted successfully.');
                    onSuccessModalOpen();
                } else {
                    toast({
                        title: 'Success',
                        description: response?.data?.message || 'Mandate details submitted successfully.',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                        position: 'top',
                    });
                }

                onClose();
            } catch (error) {
                const errorMessage = error?.data?.message || 'Failed to submit mandate details.';
                toast({
                    title: 'Error',
                    description: errorMessage,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        },
    });


    const handleEmailSend = async () => {
        try {
            const response = await sendMandateConsent({
                id: loanDetails.id,
                account_id: details.id
            }).unwrap();

            toast({
                title: 'Success',
                description: response?.message || 'Email sent successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: "top"
            });
            onSuccessModalClose();
        } catch (error) {
            toast({
                title: 'Error',
                description: error?.data?.message || 'Failed to send email',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: "top"
            });
        }
    };




    const handleToggle = () => {
        if (details?.status !== 'approved') {
            onOpen();
        }
    };

    return (
        <div className="w-full max-w-[350px]">
            <div
                className="relative overflow-hidden rounded-lg p-3"
                style={{ color: 'white' }}
            >
                {/* Background Layers */}
                <div
                    className="absolute inset-0 bg-cover bg-center bg-no-repeat z-10"
                    style={{ backgroundImage: `url(${bankBackground})` }}
                />
                <div
                    className="absolute inset-0 opacity-90 z-1"
                    style={{ backgroundColor: currentBg }}
                />

                {/* Content */}
                <div className="relative z-10">
                    <div className="flex items-center">
                        {/* Left Side */}
                        <div className="flex-1 pr-6">
                            <div className="flex gap-8 mb-4">
                                <div className="w-45 h-45 rounded-lg bg-gray-500 flex-shrink-0">
                                    {CurrentIcon && <CurrentIcon />}
                                </div>
                                <h5 className="font-semibold text-[13px] font-inter flex items-center">
                                    {details.bankname}
                                </h5>
                            </div>
                            <div>
                                <div className="flex items-center mb-2 gap-6">
                                    <h6 className="font-normal text-xs font-inter">
                                        Account Number
                                    </h6>
                                    <h6 className="px-8 py-3 rounded-lg border-2 border-white text-sm font-bold">
                                        NGN
                                    </h6>
                                </div>
                                <h5 className="font-bold text-2xl font-urbanist mt-4">
                                    {details.accountnumber}
                                </h5>
                            </div>
                        </div>

                        {/* Vertical Divider */}
                        <div className="w-[2px] h-[120px] bg-gray-300" />

                        {/* Right Side */}
                        <div className="flex-1 pl-6 flex flex-col">
                            <div className="flex flex-col items-end mb-3">
                                <h6 className="px-14 py-5 rounded-lg border-2 border-white text-sm">
                                    Status:
                                    <span className="ml-3">
                                        {details.is_active ? ' Active' : ' Inactive'}
                                    </span>
                                </h6>
                            </div>
                            <div className="flex items-center justify-between mt-5">
                                <div className="flex gap-3">
                                    <h6 className="font-semibold text-sm font-inter">
                                        Direct Debit
                                    </h6>
                                </div>
                                <Switch
                                    size="md"
                                    colorScheme="green"
                                    isChecked={details.status === 'approved'}
                                    onChange={handleToggle}
                                />
                            </div>
                            <h6 className="mb-4 text-sm mt-5">
                                Mandate Status: {details.status}
                            </h6>
                        </div>
                    </div>
                </div>

                {/* Form Modal */}
                <Modal isOpen={isOpen} onClose={onClose} isCentered>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Enable Direct Mandate Form</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <form onSubmit={formik.handleSubmit}>
                                <FormControl mb="4" isInvalid={!!formik.errors.amount}>
                                    <FormLabel>Amount</FormLabel>
                                    <Input
                                        name="amount"
                                        type="number"
                                        placeholder="Enter amount"
                                        value={formik.values.amount}
                                        onChange={formik.handleChange}
                                    />
                                </FormControl>
                                <FormControl mb="4" isInvalid={!!formik.errors.type}>
                                    <FormLabel>Type</FormLabel>
                                    <Select
                                        name="type"
                                        placeholder="Select type"
                                        value={formik.values.type}
                                        onChange={formik.handleChange}
                                    >
                                        <option value="e-mandate">E-Mandate</option>
                                        <option value="manual">Manual</option>
                                    </Select>
                                </FormControl>
                                {formik.values.type === 'manual' && (
                                    <FormControl mb="4" isInvalid={!!formik.errors.witness}>
                                        <FormLabel>Guarantor</FormLabel>
                                        <Select
                                            name="witness"
                                            placeholder="Select Guarantor"
                                            value={formik.values.witness}
                                            onChange={formik.handleChange}
                                        >
                                            {witnesses.length > 0 ? (
                                                witnesses.map((witness) => (
                                                    <option key={witness.id} value={witness.id}>
                                                        {witness.witnessname}
                                                    </option>
                                                ))
                                            ) : (
                                                <option value="" disabled>
                                                    You don't have any witnesses. Add a witness.
                                                </option>
                                            )}
                                        </Select>
                                    </FormControl>
                                )}

                                <ModalFooter>
                                    <Button variant="ghost" onClick={onClose}>
                                        Cancel
                                    </Button>
                                    <Button
                                        className="btn btn-primary rounded-md"
                                        ml="3"
                                        type="submit"
                                    >
                                        {formik.values.type === 'e-mandate' ? 'Next' : 'Submit'}
                                    </Button>
                                </ModalFooter>
                            </form>
                        </ModalBody>
                    </ModalContent>
                </Modal>

                {/* Success Modal */}
                <Modal isOpen={isSuccessModalOpen} onClose={onSuccessModalClose} isCentered size="lg">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Success</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <div style={{ lineHeight: '1.7', wordSpacing: '0.3em', }}>
                                {successMessage}
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="ghost" onClick={onSuccessModalClose}>
                                Cancel
                            </Button>
                            <Button
                                className="btn btn-primary rounded-md"
                                ml="3"
                                onClick={handleEmailSend}
                            >
                                Send as Email
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </div>
        </div>
    );
};